import { useAtom } from "jotai";
import { useAuthFeature } from "../../../Auth";
import {
  activeStepAtom,
  resetDomainFormActionAtom,
  domainNameAtom,
  domainStatusAtom,
} from "../../states";
import { useEffect } from "react";
import { getDomain } from "../../helpers";
import { DomainStatus } from "../../models";
import { DomainData, useDomainsData } from "../../../DomainsList";

let intervalRef: NodeJS.Timer | undefined;

export const useStep3BL = (): [
  domainName: string,
  domainStatus: string,
  resetDomainFormAction: Function
] => {
  const [,,getAuthToken] = useAuthFeature();
  const [domainName] = useAtom(domainNameAtom);
  const [, setActiveStep] = useAtom(activeStepAtom);
  const [domainStatus, setDomainStatus] = useAtom(domainStatusAtom);
  const [, resetDomainFormAction] = useAtom(resetDomainFormActionAtom);
  const [, updateDomainsDataList] = useDomainsData();

  useEffect(() => {
    if (intervalRef) {
      clearInterval(intervalRef);
    }

    intervalRef = setInterval(async () => {
      const accessToken = await getAuthToken();
      const response = await getDomain(accessToken, domainName);
      
      if (response?.domainName?.S) {
        const domainData = response as DomainData;
        updateDomainsDataList(domainData);
      }

      if (response?.domainStatus?.S === DomainStatus.WaitingToVerifyDNS) {
        setDomainStatus(response.domainStatus.S);
      }

      if (response?.domainStatus?.S === DomainStatus.WaitingToInstall) {
        setDomainStatus(response.domainStatus.S);
      }

      if (
        response?.domainStatus?.S === DomainStatus.Working ||
        response?.domainStatus?.S === DomainStatus.Error
      ) {
        if (intervalRef) clearInterval(intervalRef);
        setDomainStatus(response.domainStatus.S);
        setActiveStep((previousStep) => previousStep + 1);
      }
    }, 5000);

    return () => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [domainName, domainStatus, resetDomainFormAction];
};
