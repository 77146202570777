import { reportSignupToGoogleAds } from "../../../lib/gtag";
import { reportSignupToLinkedin } from "../../../lib/linkedin";
import { reportSignupToTwitter } from "../../../lib/twitter";

export const writeSignUpToLocalStorage = () => {
  localStorage.setItem("signup", new Date().getTime().toString());
};

const checkSignUpInLocalStorage = () => {
  const signup = localStorage.getItem("signup");
  if (signup) {
    localStorage.removeItem("signup");
    return true;
  }

  return false;
};

export const checkAndReportSignupToAnalytics = (email: string) => {
  if (checkSignUpInLocalStorage()) {
    reportSignupToGoogleAds();
    reportSignupToLinkedin();
    reportSignupToTwitter(email);
  }
}