import { FC, useEffect, useState } from "react";
import { convertSecToMinAndSec } from "../../../utils";

let interval: NodeJS.Timer;

type timerProps = {
  onComplete?: () => void;
};

const Timer: FC<timerProps> = ({ onComplete }) => {
  const [time, setTime] = useState(120);

  useEffect(() => {
    if (time === 1 && onComplete) {
      onComplete();
    }

    if (interval) {
      clearInterval(interval);
    }

    interval = setInterval(() => {
      setTime(time - 1);
    }, 1000);
  }, [time, setTime, onComplete]);  

  return <>{convertSecToMinAndSec(time)}</>;
};

export default Timer;
