import Typography from "@mui/material/Typography";
import SubmitButton from "../../../components/SubmitButton";
import { useStep2BL } from "../hooks/BL/step2";
import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import { URL_APP_VIEW_ALL_DOMAINS } from "../../../data/URLs";
import DNSRecordsTable from "../components/DNSRecordTable";

const Step2 = () => {
  const [
    domainName,
    progressing,
    submitStep2,
    resetDomainFormAction
  ] = useStep2BL();
  const Navigate = useNavigate();

  const renderBackButton = () => {
    if (progressing) {
      return null;
    }

    return (
      <BackButton
        text="Back to Home"
        onClick={() => {
          resetDomainFormAction(null);
          Navigate(URL_APP_VIEW_ALL_DOMAINS);
        }}
      />
    );
  };

  return (
    <form
      className="is-centered"
      onSubmit={(e) => {
        e.preventDefault();
        submitStep2();
      }}
    >
      <Typography variant="h6">Add DNS Record</Typography>
      <Typography variant="body1" textAlign="center">
        Go to DNS Management section of your domain provider
      </Typography>
      <Typography
        variant="caption"
        textAlign="center"
        style={{ maxWidth: "500px", padding: "0 10px" }}
      >
        Delete any old "A" Record for {domainName} and then add the following
        one:
      </Typography>
      <DNSRecordsTable />
      <br />
      <SubmitButton
        showProgress={progressing}
        progressText="Veryfying DNS Records"
        defaultText="Ok, I've added these now"
      />
      {renderBackButton()}      
    </form>
  );
};

export default Step2;
