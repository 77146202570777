import { loadStripe, Stripe } from "@stripe/stripe-js";
import { URL_APP_BILLING, URL_APP_BILLING_SUCCESS } from "../../../data/URLs";

export const getStripe = (): Promise<Stripe | null> => {
  const publishableKey =
    process.env.NODE_ENV === "production"
      ? (process.env.REACT_APP_STRIPE_PUB_KEY_PROD as string)
      : (process.env.REACT_APP_STRIPE_PUB_KEY_TEST as string);
  return loadStripe(publishableKey);
};

export const redirectToStripeCheckout = async (
  stripe: Stripe,
  email: string,
  userID: string
) => {
  const price =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_PRODUCT_ID_PROD
      : process.env.REACT_APP_STRIPE_PRODUCT_ID_TEST;

  await stripe.redirectToCheckout({
    lineItems: [
      {
        price,
        quantity: 1,
      },
    ],
    mode: "subscription",
    successUrl: `${window.location.protocol}//${window.location.host}${URL_APP_BILLING_SUCCESS}?session_id={CHECKOUT_SESSION_ID}`,
    cancelUrl: `${window.location.protocol}//${window.location.host}${URL_APP_BILLING}`,
    customerEmail: email,
    clientReferenceId: userID,
  });
};
