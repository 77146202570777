import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import {AuthSignout} from "../features/Auth";
import MenuIcon from "./MenuIcon";
// import QrCode2Icon from '@mui/icons-material/QrCode2';
// import CampaignIcon from '@mui/icons-material/Campaign';
// import ContactPageIcon from '@mui/icons-material/ContactPage';
import { MyLinksListButton } from "../features/LinksList";
import { MyDomainsListButton } from "../features/DomainsList";
import { getMenuHighlightValue, storeMenuHighlightValue } from "../utils/localstorage";

const MenuButton = () => {
  const [highlight, setHighlight] = useState<boolean>(getMenuHighlightValue());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setHighlight(false);
    storeMenuHighlightValue();    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="hide-desktop-only"
        id="main-navigation-menu-button"
        aria-controls={open ? "main-navigation-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}        
      >
        <MenuIcon highlight={highlight} />
      </IconButton>
      <Menu
        id="main-navigation-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "main-navigation-menu-button",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MyLinksListButton className="hide-desktop-only" mobile={true} />
        <MyDomainsListButton className="hide-desktop-only" mobile={true} />
        <AuthSignout mobile={true} />
      </Menu>
    </>
  );
};

export default MenuButton;
