let parseDomainNames: any;
import("tldts").then((lib) => {
  parseDomainNames = lib?.parse;
});

export const isInvalidDomainName = (domainName: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  if (parseDomainNames && regexp.test(domainName)) {
    const tldts = parseDomainNames(domainName, { allowPrivateDomains: true });
    return !tldts.isIcann;
  }
  return !regexp.test(domainName);
};

export const addDomain = async (
  accessToken: string | null,
  domainName: string
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/domain/add");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domainName: domainName.toLowerCase(),
      subDomain: domainName,
    }),
  });
  const data = await response.json();
  return data;
};

export const checkDomainDNS = async (
  accessToken: string | null,
  domainName: string
) => {
  var url = new URL(
    process.env.REACT_APP_API_GATEWAY_BASEURL + "/domain/dns/check"
  );
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domainName: domainName.toLowerCase(),
    }),
  });
  const data = await response.json();
  return data;
};

export const getDomain = async (
  accessToken: string | null,
  domainName: string
) => {
  try {
    var url = new URL(
      process.env.REACT_APP_API_GATEWAY_BASEURL + "/domain/get"
    );
    if (domainName) {
      url.search = new URLSearchParams({
        domainName: domainName.toLowerCase(),
      }).toString();
    }
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const removeProtocolIfNeeded = (domainName: string): string => {
  let correctedDomainName = domainName;
  if (domainName.includes("http:")) {
    correctedDomainName = correctedDomainName.split("http:").join("");
  }

  if (domainName.includes("https:")) {
    correctedDomainName = correctedDomainName.split("https:").join("");
  }

  if (domainName.includes("/")) {
    correctedDomainName = correctedDomainName.split("/").join("");
  }

  if (domainName.includes(" ")) {
    correctedDomainName = correctedDomainName.split(" ").join("");
  }

  return correctedDomainName;
};

export const extractSubDomain = (domainName: string) => {
  let subDomain = "@";
  if (parseDomainNames) {
    const tldts = parseDomainNames(domainName);
    subDomain = tldts.subdomain ? tldts.subdomain : subDomain;
    return subDomain;
  }
  
  const domainComponents = domainName.toLowerCase().split(".");
  if (domainComponents.length < 3) {
    return subDomain;
  }

  subDomain = "";
  for (let index = 0; index < domainComponents.length - 2; index++) {
    subDomain += domainComponents[index];
  }
  return subDomain;
};
