import { isIndia } from "../../../utils";
import { UserInfo } from "../../Auth";
import { LOCALSTORAGE_PAYMENT_CURRENCY } from "../data";
import { Currency } from "../models";

export const isPaymentNeeded = (userInfo: UserInfo | null) => {
  const paidUser =
    userInfo?.subsID &&
    userInfo?.subsEnd &&
    userInfo?.subsEnd * 1000 > Date.now();
  return !paidUser;
};

export const getInitialCurrency = (): Currency => {
  const storedCurrency = localStorage.getItem(LOCALSTORAGE_PAYMENT_CURRENCY);
  if (storedCurrency) {
    return storedCurrency as Currency;
  }

  if (isIndia()) {
    return Currency.INR;
  }
  return Currency.USD;
};

export const storeInitialCurrency = (currency: Currency) => {
  localStorage.setItem(LOCALSTORAGE_PAYMENT_CURRENCY, currency)
};
