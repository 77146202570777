import { useAtom } from "jotai";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { isValidHttpDomain, isValidHttpUrl, makeAURL } from "../../../../utils";
import { useAuthFeature } from "../../../Auth";
import { createLink, getLinkAvailability, hasBlacklistedDomain, isARedirectLoop } from "../../helpers";
import {
  linkPathAtom,
  progressingAtom,
  resetLinkFormActionAtom,
  validation1Atom,
  subDomainAtom,
  redirectURLAtom,
  is301Atom,
  titleOGAtom,
  descriptionOGAtom,
  imageURLOGAtom,
  imageTypeOGAtom,
} from "../../states";
import { useLoadDomainNamesListBL } from "../../../DomainsList";
import { useCreateLinkFormMode } from "..";
import { LocalStateAction } from "../../../../models";
import { useLinkData } from "../../../LinksList";
import { ERROR_CANT_CREATE_LINK_WO_UPGRADE } from "../../data";
import { useState } from "react";

export const useDuplicateDialogBL = (setShowDialog: LocalStateAction<boolean>): [
  domainNameList: string[],
  subDomain: string,
  linkPath: string,
  progressing: boolean,
  validation: string,
  showUpgrade: boolean,
  setShowUpgrade: LocalStateAction<boolean>,
  submitStep2: Function,
  handleClose: Function
] => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [,,getAuthToken] = useAuthFeature();
  const [subDomain] = useAtom(subDomainAtom);
  const [linkPath] = useAtom(linkPathAtom);
  const [redirectURL] = useAtom(redirectURLAtom);
  const [is301] = useAtom(is301Atom);
  const [titleOG] = useAtom(titleOGAtom);
  const [descriptionOG] = useAtom(descriptionOGAtom);
  const [imageURLOG] = useAtom(imageURLOGAtom);
  const [imageTypeOG] = useAtom(imageTypeOGAtom);
  const [progressing, setProgressing] = useAtom(progressingAtom);
  const [validation, setValidation] = useAtom(validation1Atom);
  const [, resetLinkFormAction] = useAtom(resetLinkFormActionAtom);
  const [addToLinksDataList] = useLinkData();
  const [domainNameList] = useLoadDomainNamesListBL();
  useCreateLinkFormMode(domainNameList);

  const handleClose = () => {
    if (progressing) {
      return;
    }
    resetLinkFormAction();
    setShowDialog(false);
  };

  const submitDuplicateDialog = async () => {
    if (validation) {
      return;
    }

    const noSubdomain = subDomain === "";
    const noLinkPath = linkPath === "";
    const isValidURL =
      isValidHttpDomain(makeAURL(subDomain, "")) &&
      isValidHttpUrl(makeAURL(subDomain, linkPath));
    if (noSubdomain) {
      setValidation("Subdomain can't be empty. It should be <Your-Brand>.vstory.co");
      return;
    }

    if (noLinkPath) {
      setValidation("link path can't be empty. It should be <Your-Brand>.vstory.co/<Some-Meaningful-Path>");
      return;
    }

    if (!isValidURL) {
      setValidation(`${subDomain}.vstory.co/${linkPath} is not a valid link`);
      return;
    }

    if (hasBlacklistedDomain(subDomain)) {
      setValidation(`${subDomain}.vstory.co is not allowed`);
      return;
    }

    if (isARedirectLoop(subDomain, linkPath, redirectURL)) {
      setValidation("your link can't be same as your Redirect Destination");
      return;
    }

    setProgressing(true);
    try {
      const accessToken = await getAuthToken();
      const isLinkAvailable = await getLinkAvailability(
        accessToken,
        subDomain,
        linkPath
      );
      if (isLinkAvailable) {        
        const response = await createLink(
          accessToken,
          subDomain,
          linkPath,
          redirectURL,
          is301,
          titleOG,
          descriptionOG,
          imageURLOG,
          imageTypeOG
        );

        if (response && response.error) {
          if (response.error === ERROR_CANT_CREATE_LINK_WO_UPGRADE) {
            setShowUpgrade(true);
          }
          setProgressing(false);
          return;
        }

        addToLinksDataList(
          subDomain,
          linkPath,
          redirectURL,
          is301,
          titleOG,
          descriptionOG,
          imageURLOG,
          imageTypeOG
        );
        handleClose();
      } else {
        setValidation("someone else is already using this exact link");
      }
    } catch (err) {
      alert(ERROR_PREFIX_FOR_ALERT + err);
    }
    setProgressing(false);
  };  

  return [domainNameList, subDomain, linkPath, progressing, validation, showUpgrade, setShowUpgrade, submitDuplicateDialog, handleClose];
};
