import { useState } from "react";
import { useAuthFeature } from "../../../Auth";
import { DomainData, DomainStatus } from "../../models";
import { useDomainsData } from "..";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { removeDomain } from "../../helpers";
import {
  getDomainUninstalled,
  removeDomainUninstalled,
  storeDomainUninstalled,
} from "../../../../utils/localstorage";

export const useRemoveDomainBL = (
  data: DomainData
): [removing: boolean, removeDomainFn: Function] => {
  const [, , getAuthToken] = useAuthFeature();
  const [removing, setRemoving] = useState(false);
  const [, , removeFromDomainsDataList] = useDomainsData();

  const removeDomainFn = async () => {
    setRemoving(true);
    if (shouldRemove()) {
      try {
        const accessToken = await getAuthToken();
        await removeDomain(accessToken, data.domainName.S);
        storeDomainUninstalled(data.domainName.S);
        removeFromDomainsDataList(data.domainName.S);
      } catch (e) {
        alert(ERROR_PREFIX_FOR_ALERT + e);
      }
    }
    setRemoving(false);
  };

  const shouldRemove = (): boolean => {
    if (data.domainStatus.S !== DomainStatus.Working) {
      return true;
    }

    const lastUninstalledAt = getDomainUninstalled(data.domainName.S);
    if (
      lastUninstalledAt &&
      new Date().getTime() - new Date(lastUninstalledAt).getTime() <
        24 * 7 * 3600 * 1000
    ) {
      alert(
        "You can't remove the same domain name twice in a week. Try doing it after a week"
      );
      return false;
    }

    removeDomainUninstalled(data.domainName.S);
    return true;
  };

  return [removing, removeDomainFn];
};
