import Step1 from "./Step1";
import { useAtom } from "jotai";
import { activeStepAtom, modeAtom } from "../states";
import Step2 from "./Step2";
import Step3 from "./Step3";
import SuccessStep from "../components/SuccessStep";
import StepperStrip from "../components/StepperStrip";
import { LinkFormMode } from "../models";
import { useLoadDomainNamesListBL } from "../../DomainsList";
import { useCreateLinkFormMode } from "../hooks";

const LinkFormUX = () => {
  const [mode] = useAtom(modeAtom);

  if (mode !== LinkFormMode.Update) {
    return <CreateLinkFormUX />;
  } else {
    return <UpdateLinkFormUX />;
  }
};

const CreateLinkFormUX = () => {
  const [activeStep] = useAtom(activeStepAtom);
  let className = "container-topped";
  const [domainNameList] = useLoadDomainNamesListBL();
  useCreateLinkFormMode(domainNameList);

  return (
    <div className={`is-centered ${className}`}>
      {activeStep < 3 ? <StepperStrip /> : null}
      <br />
      {activeStep === 0 ? <Step1 /> : null}
      {activeStep === 1 ? <Step2 domainNameList={domainNameList} /> : null}
      {activeStep === 2 ? <Step3 /> : null}
      {activeStep === 3 ? <SuccessStep /> : null}
    </div>
  );
};

const UpdateLinkFormUX = () => {
  const [activeStep] = useAtom(activeStepAtom);
  const className = "container-topped";

  return (
    <div className={`is-centered ${className}`}>
      {activeStep < 2 ? <StepperStrip /> : null}
      <br />
      {activeStep === 0 ? <Step1 /> : null}
      {activeStep === 1 ? <Step3 /> : null}
      {activeStep === 2 ? <SuccessStep /> : null}
    </div>
  );
};

export default LinkFormUX;
