import { useAtom } from "jotai";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { URL_SIGNUP, URL_VERIFY_OTP } from "../../../../data/URLs";
import { validateEmail } from "../../../../utils";
import { ERROR_NOT_SIGNED_UP_YET1, ERROR_NOT_SIGNED_UP_YET2 } from "../../data";
import { signIn } from "../../libs/amplifyAuth";
import { progressingAtom, signInEmailAtom, authUserAtom } from "../../states";

export const useSignInBL = (): [
  email: string,
  signInValidation: boolean,
  creatingSignInOTP: boolean,
  signInFn: Function,
  onEmailChange: Function
] => {
  const [email, setEmail] = useAtom(signInEmailAtom);
  const [, setAuthUser] = useAtom(authUserAtom);
  const [signInValidation, setSignInValidation] = useState(false);
  const [creatingSignInOTP, setCreatingSignInOTP] = useAtom(progressingAtom);
  const navigate = useNavigate();

  const signInFn = async () => {
    if (!validateEmail(email)) {
      return setSignInValidation(true);
    }

    setCreatingSignInOTP(true);
    try {
      const authUser = await signIn(email);
      setAuthUser(authUser);
      setCreatingSignInOTP(false);
      navigate(URL_VERIFY_OTP);
    } catch (err) {
      handleError(err as Error, navigate);
    }
    setCreatingSignInOTP(false);
  };

  const onEmailChange = (email: string) => {
    email = email.toLowerCase();
    if (validateEmail(email)) {
      setSignInValidation(false);
    }
    setEmail(email);
  };

  return [email, signInValidation, creatingSignInOTP, signInFn, onEmailChange];
};

const handleError = (error: Error, navigate: NavigateFunction) => {
  const message = error.toString();
  if (message.includes(ERROR_NOT_SIGNED_UP_YET1)) {
    return navigate(URL_SIGNUP);
  }
  if (message.includes(ERROR_NOT_SIGNED_UP_YET2)) {
    return navigate(URL_SIGNUP);
  }
  alert(ERROR_PREFIX_FOR_ALERT + error);
};
