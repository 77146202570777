import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { FC } from "react";
import "./DomainCard.css";
import { DomainData, DomainStatus } from "../models";
import Typography from "@mui/material/Typography";
import DomainCardStatusChip from "./CardStatusChip";
import RemoveButton from "./RemoveButton";
import ContinueToDomainFormButton from "./ContinueToDomainFormButton";
import DomainStatusProgress from "./DomainStatusProgress";
import CreateLinkButton from "./CreateLinkButton";

type DomainCardProps = {
  data: DomainData;
};

const DomainCard: FC<DomainCardProps> = ({ data }) => {
  return (
    <div className="domain-card-wrapper">
      <DomainCardStatusChip data={data} />
      <Card variant="outlined">
        <CardActionArea>
          <CardContent>
            <Typography variant="caption" fontWeight={700}>
              Domain name
            </Typography>
            <Typography variant="h6" fontWeight={300} sx={{ opacity: 0.7 }}>
              {data.subDomain.S}
              <DomainStatusProgress status={data.domainStatus.S} />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {getButtonAsPerStatus(data)}
          <RemoveButton data={data} />
        </CardActions>
      </Card>
    </div>
  );
};

const getButtonAsPerStatus = (data: DomainData) => {
  switch (data.domainStatus.S) {
    case DomainStatus.WaitingToAddDNS:
      return (
        <ContinueToDomainFormButton
          data={data}
          color="warning"
          label="Finish Setup"
        />
      );

    case DomainStatus.WaitingToVerifyDNS:
      return (
        <ContinueToDomainFormButton
          data={data}
          color="primary"
          label="Check Details"
        />
      );

    case DomainStatus.WaitingToInstall:
      return (
        <ContinueToDomainFormButton
          data={data}
          color="primary"
          label="Check Details"
        />
      );

    case DomainStatus.Working:
      return <CreateLinkButton data={data} />;

    default:
      return (
        <ContinueToDomainFormButton
          data={data}
          color="primary"
          label="Check Details"
        />
      );
  }
};

export default DomainCard;
