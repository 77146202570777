export const validateEmail = (email: string): boolean => {
  const validRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const validateOTP = (authOTP: string): boolean => {
  if (authOTP.length === 6 && /^[0-9]*$/.test(authOTP)) {
    return true;
  } else {
    return false;
  }
};

export const convertSecToMinAndSec = (time: number): string => {
  if (time > 60) {
    const min = Math.floor(time / 60);
    const sec = time - Math.floor(time / 60) * 60;
    return `${min} min ${sec} sec`;
  } else {
    return `${time} sec`;
  }
};

export const getRandomString = (bytes: number): string => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
};

export const intToHex = (nr: number): string => {
  return nr.toString(16).padStart(2, "0");
};

export const readFile = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
};

export const getWidthHeight = (src: string): Promise<[number, number]> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      resolve([img.width, img.height]);
    };
  });
};

export const isValidHttpDomain = (inputDomain: string): boolean => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(inputDomain);
};

export const isValidHttpUrl = (inputURL: string): boolean => {
  let url;
  try {
    url = new URL(inputURL);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const removeCharacters = (text: string, characters: string[]) => {
  characters.forEach((character) => {
    text = text.split(character).join("");
  });

  return text;
};

export const makeFullPath = (subDomain: string, linkPath: string): string => {
  return subDomain.toLowerCase() + "/" + linkPath;
};

export const isCustomDomain = (subDomain: string) =>
  subDomain.includes("customDomain-_-@");

export const makeAURL = (subDomain: string, linkPath: string): string => {
  if (isCustomDomain(subDomain)) {
    if (!linkPath) {
      return `https://${subDomain.split("customDomain-_-@").join("")}`;
    }

    return `https://${subDomain
      .split("customDomain-_-@")
      .join("")}/${linkPath}`;
  }

  if (!linkPath) {
    return `https://${subDomain}.vstory.co`;
  }

  return `https://${subDomain}.vstory.co/${linkPath}`;
};

export const isIndia = () => {
  let hasIndiaTimezone = false;
  try {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone === "Asia/Calcutta") {
      hasIndiaTimezone = true;
    }
  } catch (e) {}

  return hasIndiaTimezone;
};

export const getLocalTimezoneName = () => {
  return new Date()
    .toLocaleDateString("en-US", {
      day: "2-digit",
      timeZoneName: "long",
    })
    .slice(4);
};

export const getLocalTimeDifference = () => {
  return -1 * new Date().getTimezoneOffset();
};
