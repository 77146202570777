import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import SubmitButton from "../../../components/SubmitButton";
import DomainNameForm from "../components/DomainNameForm";
import { useStep1BL } from "../hooks/BL/step1";
import BackButton from "../components/BackButton";
import { URL_APP_VIEW_ALL_DOMAINS } from "../../../data/URLs";
import { useNavigate } from "react-router-dom";

const Step1 = () => {
  const [validation, progressing, submitStep1, resetDomainFormAction] =
    useStep1BL();
  const Navigate = useNavigate();

  const renderBackButton = () => {
    if (progressing) {
      return null;
    }

    return (
      <BackButton
        text="Back to Home"
        onClick={() => {
          resetDomainFormAction(null);
          Navigate(URL_APP_VIEW_ALL_DOMAINS);
        }}
      />
    );
  };

  return (
    <form
      className="is-centered has-padding-5"
      onSubmit={async (e) => {
        e.preventDefault();
        submitStep1();
      }}
    >
      <Typography variant="h6" textAlign="center">
        Enter the domain name you own
      </Typography>
      <Typography variant="body1" textAlign="center">
        This will be used instead of{" "}
        <span
          style={{ display: "inline-block" }}
        >{`<Your-Brand>.vtory.co`}</span>
      </Typography>
      <Typography variant="caption" textAlign="center">
        Examples: YourBrand.io OR link.YourBrand.io OR www.YourBrand.io
      </Typography>
      <br />
      <DomainNameForm />
      {validation ? (
        <Alert
          severity="warning"
          variant="outlined"
          className="is-centered-item"
          style={{ marginBottom: "15px" }}
        >
          {validation}
        </Alert>
      ) : null}
      <SubmitButton
        showProgress={progressing}
        progressText="Checking if available"
        defaultText="Next"
      />
      {renderBackButton()}
    </form>
  );
};

export default Step1;
