import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { URL_APP_VIEW_ALL_LINKS } from "../../../data/URLs";
import ManageBillingButton from "./ManageBillingButton";
import { useReportPurchaseToGoogleBL } from "../hooks/BL";

const BillingForPaidUser = () => {
  const Navigate = useNavigate();
  const isStripeSuccessURL = useReportPurchaseToGoogleBL();

  return (
    <div className="billing-container">
      <CheckCircleIcon sx={{ fontSize: 120 }} color="success" />
      <Typography variant="h5">Congratulations!</Typography>
      <Typography
        variant="body1"
        sx={{ maxWidth: "600px", px: 5 }}
        textAlign="center"
      >
        You're now a pro user of vStory Links!
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={{ margin: "25px" }}
        onClick={() => {
          Navigate(URL_APP_VIEW_ALL_LINKS);
          if (isStripeSuccessURL) {
            Navigate(0);
          }
        }}
      >
        Back To Home
      </Button>
      <ManageBillingButton />
    </div>
  );
};

export default BillingForPaidUser;
