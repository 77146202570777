import Button from "@mui/material/Button";
import { FC } from "react";
import { useLinkFormForUpdation } from "../../LinkForm";
import { LinkData } from "../models";

type EditButtonProps = {
  data: LinkData;
};

const EditButton: FC<EditButtonProps> = ({ data }) => {
  const proceedToUpdateLink = useLinkFormForUpdation();
  return (
    <Button
      onClick={() => {
        proceedToUpdateLink(
          data.subDomain.S,
          data.linkPath.S,
          data.redirectURL.S,
          data.is301.BOOL,
          data.titleOG.S,
          data.descriptionOG.S,
          data.imageURLOG.S,
          data.imageTypeOG.S
        );
      }}
    >
      Edit
    </Button>
  );
};

export default EditButton;
