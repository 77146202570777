import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { validation1Atom, domainNameAtom } from "../states";
import { removeProtocolIfNeeded } from "../helpers";

const DomainNameForm = () => {
  const [domainName, setDomainName] = useAtom(domainNameAtom);
  const [, setValidation] = useAtom(validation1Atom);

  return (
    <TextField
      type="text"
      fullWidth={true}
      placeholder="MyDomain.co"
      autoFocus={true}
      variant="standard"
      value={domainName}
      className="has-padding-5"
      inputProps={{ className: "data-hj-allow" }}
      style={{ marginBottom: "15px" }}
      onChange={(e) => {
        let urlTyped = e.target.value;
        urlTyped = removeProtocolIfNeeded(urlTyped);
        setDomainName(urlTyped);
        setValidation("");
      }}
    />
  );
};

export default DomainNameForm;
