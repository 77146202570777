import { FC } from "react";
import "./LinkCard.css";
import Chip from "@mui/material/Chip";
import { LinkData } from "../models";

type LinkCardTagChipProps = {
  data: LinkData;
};

const LinkCardTagChip: FC<LinkCardTagChipProps> = ({ data }) => {
  if (data.tag) {
    return (
      <Chip
        className="link-card-tag-chip"
        color="success"
        size="small"
        label={data.tag.S}
      />
    );
  }
  return null;
};

export default LinkCardTagChip;
