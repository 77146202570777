import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { URL_PRIVACY_POLICY, URL_TERMS_OF_SERVICE } from "../../../data/URLs";

const NoticeForEULA = () => {
  return (
    <>
      <hr />
      <hr />
      <Divider className="is-centered-item" />
      <hr />
      <Typography
        textAlign="center"
        variant="caption"
        className="is-centered-item"
        style={{ opacity: 0.6 }}
      >
        By Clicking the button above, you agree to vStory Links's{" "}
        <Link href={URL_TERMS_OF_SERVICE} color="inherit">
          terms of service
        </Link>{" "}
        &{" "}
        <Link href={URL_PRIVACY_POLICY} color="inherit">
          privacy policy
        </Link>
      </Typography>
    </>
  );
};

export default NoticeForEULA;
