import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { URL_APP_BILLING_SUCCESS, URL_RAZORPAY_CHECKOUT_SDK } from "../../../../data/URLs";
import { useAuthFeature, UserInfo } from "../../../Auth";
import { LOCALSTORAGE_PAYMENT_DONE } from "../../data";
import { isPaymentNeeded } from "../../helpers";
import {
  createSubscription,
  initRazorpay,
  openRazorpay,
} from "../../libs/razorpay";
import { getStripe, redirectToStripeCheckout } from "../../libs/stripe";
import { Currency } from "../../models";
import { currencyAtom, paymentDoneAtom, stripeClientAtom } from "../../states";
import { useLocation, useSearchParams } from "react-router-dom";
import { reportPurchaseToGoogleAds } from "../../../../lib/gtag";
import { reportPurchaseToLinkedin } from "../../../../lib/linkedin";
import { reportPurchaseToTwitter } from "../../../../lib/twitter";

export const usePaymentNeededBL = (): [
  paymentNeeded: boolean,
  userInfo: UserInfo | null
] => {
  const [, userInfo] = useAuthFeature();
  const paymentNeeded = isPaymentNeeded(userInfo);
  useEffect(() => {
    if (!paymentNeeded) {
      localStorage.removeItem(LOCALSTORAGE_PAYMENT_DONE);
    }
  }, [paymentNeeded]);

  return [paymentNeeded, userInfo];
};

export const usePaymentClient = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [stripe, setStripe] = useAtom(stripeClientAtom);
  const [currency] = useAtom(currencyAtom);

  useEffect(() => {
    if (currency === Currency.INR) {
      const script = document.createElement("script");
      script.src = URL_RAZORPAY_CHECKOUT_SDK;
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
      };
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    } else {
      (async () => {
        if (!stripe) {
          setStripe(await getStripe());
        }

        setScriptLoaded(true);
        return () => setStripe(null);
      })();
    }
  }, [currency, stripe, setScriptLoaded, setStripe]);

  return scriptLoaded;
};

export const usePaymentProcessBL = (): [
  waitingToOpen: boolean,
  startPaymentProcess: Function
] => {
  const [waitingToOpen, setWaitingToOpen] = useState(false);
  const [, userInfo, getAuthToken] = useAuthFeature();
  const [stripe] = useAtom(stripeClientAtom);
  const [, setPaymentDone] = useAtom(paymentDoneAtom);
  const [currency] = useAtom(currencyAtom);

  const onPaymentSuccess = () => {
    localStorage.setItem(LOCALSTORAGE_PAYMENT_DONE, "true");
    setPaymentDone(true);
  };

  const startPaymentProcess = async () => {
    if (currency === "INR") {
      startPaymentProcessForINR();
    } else {
      startPaymentProcessForUSD();
    }
  };

  const startPaymentProcessForUSD = async () => {
    if (!userInfo?.username || !userInfo?.email || !stripe) {
      return;
    }

    setWaitingToOpen(true);
    try {
      await redirectToStripeCheckout(
        stripe,
        userInfo?.email,
        userInfo?.username
      );
    } catch (e) {
      alert(ERROR_PREFIX_FOR_ALERT + e);
    }
    setWaitingToOpen(false);
  };

  const startPaymentProcessForINR = async () => {
    if (!userInfo?.username || !userInfo?.email) {
      return;
    }

    setWaitingToOpen(true);
    try {
      const accessToken = await getAuthToken();
      const { id } = await createSubscription(accessToken);
      initRazorpay(userInfo?.username, userInfo?.email, id, onPaymentSuccess);
      openRazorpay();
    } catch (e) {
      alert(ERROR_PREFIX_FOR_ALERT + e);
    }
    setWaitingToOpen(false);
  };

  return [waitingToOpen, startPaymentProcess];
};

export const useReportPurchaseToGoogleBL = (): boolean => {
  const Location = useLocation();
  const [URLSearchParams] = useSearchParams();
  const isStripeSuccessURL = Location.pathname === URL_APP_BILLING_SUCCESS;
  const sessionID = URLSearchParams && URLSearchParams.get("session_id");

  useEffect(()=>{
    if (isStripeSuccessURL && sessionID) {
      reportPurchaseToGoogleAds(sessionID);
      reportPurchaseToLinkedin();
      reportPurchaseToTwitter();
    }
  }, [isStripeSuccessURL, sessionID]);

  return isStripeSuccessURL;
};
