import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import SubmitButton from "../../../components/SubmitButton";
import RedirectDestinationForm from "../components/RedirectDestinationForm";
import { useStep1BL } from "../hooks/BL/step1";
import BackButton from "../components/BackButton";
import { URL_APP_VIEW_ALL_LINKS } from "../../../data/URLs";
import { useNavigate } from "react-router-dom";
import Is301Checkbox from "../components/Is301Checkbox";

const Step1 = () => {
  const [
    validation,
    progressing,
    submitStep1,
    resetLinkFormAction,
  ] = useStep1BL();
  const Navigate = useNavigate();

  const renderBackButton = () => {
    if (progressing) {
      return null;
    }

    return (
      <BackButton
        text="Back to Home"
        onClick={() => {
          resetLinkFormAction(null);
          Navigate(URL_APP_VIEW_ALL_LINKS);
        }}
      />
    );
  };

  return (
    <form
      className="is-centered has-padding-5"
      onSubmit={async (e) => {
        e.preventDefault();
        submitStep1();
      }}
    >
      <Typography variant="h6" textAlign="center">
        Redirect destination
      </Typography>
      <Typography variant="body1" textAlign="center">
        Where do you want your audience to go?
      </Typography>
      <Typography variant="caption" textAlign="center">
        This is where your vStory Link will redirect
      </Typography>
      <br />
      <RedirectDestinationForm />
      {validation ? (
        <Alert
          severity="warning"
          variant="outlined"
          className="is-centered-item"
          style={{ marginBottom: "15px" }}
        >
          {validation}
        </Alert>
      ) : (
        <Is301Checkbox className="is-centered-item" />
      )}
      <SubmitButton
        showProgress={progressing}
        progressText="Fetching details"
        defaultText="Next"
      />
      {renderBackButton()}
    </form>
  );
};

export default Step1;
