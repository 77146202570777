import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./EmptyScreen.css";
import { URL_APP_ADD_DOMAIN } from "../../../data/URLs";
import { useNavigate } from "react-router-dom";

const EmptyScreen = () => {
  const Navigate = useNavigate();

  return (
    <div className="domain-list-empty-screen">
      <Typography variant="h5">Integrate your own domain</Typography>
      <iframe
        style={{
          width: "270px",
          height: "170px",
          marginTop: "10px",
          borderRadius: "7px",
          border: "none",
          backgroundColor: "#eeeeee",          
        }}
        src="https://www.youtube.com/embed/WK7NyiO5h3U"
        title="How to use custom domain name in your trackable links?"        
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <Typography variant="body2" style={{maxWidth: "330px", marginTop: "15px"}} align="center">
        You can integrate any domain you own, and create links like
        YourDomain.io/something OR link.YourDomain.io/somePath.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ m: 2 }}
        onClick={() => Navigate(URL_APP_ADD_DOMAIN)}
      >
        Add your own domain
      </Button>
    </div>
  );
};

export default EmptyScreen;
