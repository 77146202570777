import { FC } from "react";
import { LinkData } from "../models";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { LocalStateAction } from "../../../models";
import CircularProgress from "@mui/material/CircularProgress";
import { useDownloadQRCodeBL } from "../hooks/BL";
import { makeAURL } from "../../../utils";
import Box from "@mui/material/Box";

type QRCodeDialogProps = {
  data: LinkData;
  showDialog: boolean;
  setShowDialog: LocalStateAction<boolean>;
};

const QRCodeDialog: FC<QRCodeDialogProps> = ({
  data,
  showDialog,
  setShowDialog,
}) => {
  const [downloading, QRCodeAndControls, onDialogClose, onDialogSubmit] =
    useDownloadQRCodeBL(data, showDialog, setShowDialog);

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => onDialogClose()}
      aria-labelledby="tag-link-alert-title"
      aria-describedby="tag-link-alert-description"
    >
      {QRCodeAndControls ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onDialogSubmit();
          }}
        >
          <DialogTitle id="alert-dialog-title">Download QR Code</DialogTitle>
          <DialogContent id="alert-dialog-description">
            <QRCodeAndControls
              value={makeAURL(data.subDomain.S, data.linkPath.S)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => onDialogClose()}>Cancel</Button>
            <Button
              disabled={downloading}
              type="submit"
              endIcon={
                downloading ? (
                  <CircularProgress size={14} color="inherit" />
                ) : null
              }
            >
              {downloading ? "Downloading" : "Download"}
            </Button>
          </DialogActions>
        </form>
      ) : (
        <Box sx={{ p: 5 }}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default QRCodeDialog;
