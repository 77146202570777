import { FC } from "react";
import { LinkData } from "../models";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalStateAction } from "../../../models";
import CircularProgress from "@mui/material/CircularProgress";
import { useUpdateTagBL } from "../hooks/BL";
import { makeAURL } from "../../../utils";

type UpdateTagDialogProps = {
  data: LinkData;
  showDialog: boolean;
  setShowDialog: LocalStateAction<boolean>;
};

const UpdateTagDialog: FC<UpdateTagDialogProps> = ({
  data,
  showDialog,
  setShowDialog,
}) => {
  const [tag, saving, setTag, onDialogClose, onDialogSubmit] = useUpdateTagBL(data, setShowDialog);

  return (
    <Dialog
      open={showDialog}
      onClose={(e)=>onDialogClose()}
      aria-labelledby="tag-link-alert-title"
      aria-describedby="tag-link-alert-description"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onDialogSubmit();
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {data?.tag?.S ? "Edit the tag" : "Add a tag"}
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
          tag for&nbsp;
          <strong>{`${makeAURL(data.subDomain.S, data.linkPath.S).split("https://").join("")}`}</strong>
          <br />
          <TextField
            type="text"
            margin="dense"
            placeholder="Type text for tag here"
            variant="standard"
            inputProps={{ maxLength: 25, className: "data-hj-allow" }}
            autoFocus
            fullWidth
            onChange={(e) => setTag(e.target.value)}
            value={tag}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e)=>onDialogClose()}>Cancel</Button>
          <Button
            disabled={saving || !tag}
            type="submit"
            endIcon={
              saving ? <CircularProgress size={14} color="inherit" /> : null
            }
          >
            {saving ? "Saving" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateTagDialog;
