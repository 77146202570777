import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { Currency } from "../models";
import { currencyAtom } from "../states";

const ManageBillingButton = () => {
  const [currency] = useAtom(currencyAtom);

  if (currency === Currency.INR) {
    return null;
  }

  return (
    <>
      <Typography variant="body1" sx={{ mt: 5, opacity: 0.2 }} textAlign="center" whiteSpace="nowrap" className="show-desktop-only">
        -------------------------------------------------------- OR
        --------------------------------------------------------
      </Typography>
      <Typography variant="body1" sx={{ mt: 5, opacity: 0.2 }} textAlign="center" whiteSpace="nowrap" className="hide-desktop-only">
        ------------------------------------ OR
        ------------------------------------
      </Typography>
      <Alert severity="success" sx={{ mt: 2, px: 7 }} icon={false}>
        <Typography variant="body2" textAlign="center">
          To manage all your billing
          <br />
          <Button
            variant="outlined"
            color="success"
            sx={{ m: 1, px: 5 }}
            onClick={() => {
              let customerPortalURL;
              if (process.env.NODE_ENV === "production") {
                customerPortalURL = process.env
                  .REACT_APP_STRIPE_CUSTOMER_PORTAL_PROD as string;
              } else {
                customerPortalURL = process.env
                  .REACT_APP_STRIPE_CUSTOMER_PORTAL_TEST as string;
              }
              window.open(customerPortalURL, "_blank");
            }}
          >
            click here
          </Button>
        </Typography>
      </Alert>
    </>
  );
};

export default ManageBillingButton;
