import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

const NextLinkSetLoading = () => {
  return (
    <div style={{ textAlign: "center", padding: 20 }}>
      <LinearProgress />
      <br />
      <Typography>Loading more links</Typography>
    </div>
  );
};

export default NextLinkSetLoading;
