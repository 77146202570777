import { useAtom } from "jotai";
import { ChangeEvent, useCallback, useState } from "react";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { uploadImage } from "../../../../lib/amplifyStorage";
import { LocalStateAction } from "../../../../models";
import { makeFullPath, readFile } from "../../../../utils";
import { useAuthFeature } from "../../../Auth";
import { useLinkData } from "../../../LinksList";
import { ERROR_CANT_CREATE_LINK_WO_UPGRADE } from "../../data";
import { createLink, getUploadedImageURL, updateLink } from "../../helpers";
import { getCroppedImg } from "../../helpers/canvas";
import { LinkFormMode } from "../../models";
import {
  activeStepAtom,
  imgURLToCropAtom,
  descriptionOGAtom,
  imageTypeOGAtom,
  imageURLOGAtom,
  titleOGAtom,
  linkPathAtom,
  progressingAtom,
  redirectURLAtom,
  subDomainAtom,
  modeAtom,
  is301Atom,
} from "../../states";

export const useStep3BL = (): [
  mode: LinkFormMode,
  is301: boolean,
  progressing: boolean,
  showUpgrade: boolean,
  setShowUpgrade: LocalStateAction<boolean>,
  submitStep3: Function
] => {
  const [,,getAuthToken] = useAuthFeature();
  const [, setActiveStep] = useAtom(activeStepAtom);
  const [mode] = useAtom(modeAtom);
  const [subDomain] = useAtom(subDomainAtom);
  const [linkPath] = useAtom(linkPathAtom);
  const [redirectURL] = useAtom(redirectURLAtom);
  const [is301] = useAtom(is301Atom);
  const [titleOG] = useAtom(titleOGAtom);
  const [descriptionOG] = useAtom(descriptionOGAtom);
  const [imageURLOG] = useAtom(imageURLOGAtom);
  const [imageTypeOG] = useAtom(imageTypeOGAtom);
  const [progressing, setProgressing] = useAtom(progressingAtom);
  const [addToLinksDataList, updateLinksDataList] = useLinkData();
  const [showUpgrade, setShowUpgrade] = useState(false);

  const submitStep3 = async () => {
    setProgressing(true);
    try {
      const accessToken = await getAuthToken();
      if (mode !== LinkFormMode.Update) {
        const response = await createLink(
          accessToken,
          subDomain,
          linkPath,
          redirectURL,
          is301,
          titleOG,
          descriptionOG,
          imageURLOG,
          imageTypeOG
        );

        if (response && response.error) {
          if (response.error === ERROR_CANT_CREATE_LINK_WO_UPGRADE) {
            setShowUpgrade(true);
          }
          setProgressing(false);
          return;
        }

        addToLinksDataList(
          subDomain,
          linkPath,
          redirectURL,
          is301,
          titleOG,
          descriptionOG,
          imageURLOG,
          imageTypeOG
        );
      } else {
        const fullPath = makeFullPath(subDomain, linkPath);
        await updateLink(
          accessToken,
          subDomain,
          linkPath,
          redirectURL,
          is301,
          titleOG,
          descriptionOG,
          imageURLOG,
          imageTypeOG
        );
        updateLinksDataList(
          fullPath,
          redirectURL,
          is301,
          titleOG,
          descriptionOG,
          imageURLOG,
          imageTypeOG
        );
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (err) {
      // handle create link error
    }
    setProgressing(false);
  };

  return [mode, is301, progressing, showUpgrade, setShowUpgrade, submitStep3];
};

export const useImageInputBL = (): [
  imgURLToCrop: string,
  imageReading: boolean,
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void
] => {
  const [imageReading, setImageReading] = useState(false);
  const [imgURLToCrop, setImgURLToCrop] = useAtom(imgURLToCropAtom);

  const onImageChange = async (file: File) => {
    setImageReading(true);
    const imageDataUrl = await readFile(file);
    setImgURLToCrop(imageDataUrl);
    setImageReading(false);
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      if (file.size >= 8000000) {
        return alert("Please upload image of size less than 8 mb");
      }

      await onImageChange(file);

      e.target.value = "";
      e.target.files = null;
    }
  };

  return [imgURLToCrop, imageReading, onFileChange];
};

export const useImageCropAndUploadBL = (
  rotation: number,
  croppedAreaPixels: any
): [
  imageUploadProgress: number,
  imageUploading: boolean,
  cropAndUploadImage: Function
] => {
  const [subDomain] = useAtom(subDomainAtom);
  const [linkPath] = useAtom(linkPathAtom);
  const [, userInfo] = useAuthFeature();
  const [, setImageURLOG] = useAtom(imageURLOGAtom);
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [imgURLToCrop, setImgURLToCrop] = useAtom(imgURLToCropAtom);
  const identityID = userInfo ? userInfo.ID : null;

  const uploadOpenGraphImage = useCallback(
    async (file: File) => {
      setImageUploading(true);
      try {
        const fileName = `${subDomain.toLowerCase()}-${linkPath}.jpg`;
        const contentType = "image/jpeg";
        await uploadImage(file, fileName, contentType, ({ loaded, total }) => {
          const progressPercentage = Math.floor((loaded * 100) / total);
          setImageUploadProgress(progressPercentage);
        });
        const uploadedImageURL = getUploadedImageURL(
          identityID,
          subDomain,
          linkPath
        );
        setImageURLOG(uploadedImageURL);
      } catch (err) {
        alert(ERROR_PREFIX_FOR_ALERT + err);
        // handle file uploading error
      }
      setImageUploadProgress(0);
      setImageUploading(false);
      setImgURLToCrop("");
    },
    [
      identityID,
      subDomain,
      linkPath,
      setImageURLOG,
      setImageUploading,
      setImageUploadProgress,
      setImgURLToCrop,
    ]
  );

  const cropAndUploadImage = useCallback(async () => {
    try {
      if (imgURLToCrop) {
        const finalCroppedImage = await getCroppedImg(
          imgURLToCrop,
          croppedAreaPixels,
          rotation
        );
        if (finalCroppedImage && identityID) {
          uploadOpenGraphImage(finalCroppedImage);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    imgURLToCrop,
    croppedAreaPixels,
    rotation,
    identityID,
    uploadOpenGraphImage,
  ]);

  return [imageUploadProgress, imageUploading, cropAndUploadImage];
};
