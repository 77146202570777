import {
  getLocalTimeDifference,
  getLocalTimezoneName,
  isCustomDomain,
  makeFullPath,
} from "../../../utils";
import { blacklistedDomains } from "../data/blacklist";
import { LinkFormMode, URLType } from "../models";

export const hasBlacklistedDomain = (subDomain: string): boolean => {
  let isOnlynum = /^\d+$/.test(subDomain);
  if (isOnlynum) return true;

  const match = blacklistedDomains.find(
    (blacklistedSubDomain) => blacklistedSubDomain === subDomain.toLowerCase()
  );
  return !!match;
};

export const isARedirectLoop = (
  subDomain: string,
  linkPath: string,
  redirectURL: string
): boolean => {
  const url = new URL(redirectURL);
  const sameHostName = isCustomDomain(subDomain)
    ? url.hostname ===
      subDomain.split("customDomain-_-@").join("").toLowerCase()
    : url.hostname === `${subDomain.toLowerCase()}.vstory.co`;
  const sameLinkPath = url.pathname === `/${linkPath}`;
  return sameHostName && sameLinkPath;
};

export const getOpenGraphData = async (
  accessToken: string | null,
  redirectURL: string
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/OG/get");
  var params = { URL: redirectURL };
  url.search = new URLSearchParams(params).toString();
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data;
};

type GetLinkAvailabilityResponse = { islinkAvailable: boolean };

export const getLinkAvailability = async (
  accessToken: string | null,
  subDomain: string,
  linkPath: string
) => {
  try {
    var url = new URL(
      process.env.REACT_APP_API_GATEWAY_BASEURL + "/link/availability/get"
    );
    var params = { fullPath: makeFullPath(subDomain, linkPath) };
    url.search = new URLSearchParams(params).toString();
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: GetLinkAvailabilityResponse = await response.json();
    return data.islinkAvailable;
  } catch (err) {
    console.log(err);
  }
};

export const createLink = async (
  accessToken: string | null,
  subDomain: string,
  linkPath: string,
  redirectURL: string,
  is301: boolean,
  titleOG: string,
  descriptionOG: string,
  imageURLOG: string,
  imageTypeOG: string,
  parent: string = ""
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/link/create");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subDomain,
      linkPath,
      redirectURL,
      is301,
      titleOG,
      descriptionOG,
      imageURLOG,
      imageTypeOG,
      parent,
      timeDifference: getLocalTimeDifference(),
      timeZoneName: getLocalTimezoneName(),
    }),
  });
  const data = await response.json();
  return data;
};

export const updateLink = async (
  accessToken: string | null,
  subDomain: string,
  linkPath: string,
  redirectURL: string,
  is301: boolean,
  titleOG: string,
  descriptionOG: string,
  imageURLOG: string,
  imageTypeOG: string
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/link/update");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subDomain,
      linkPath,
      redirectURL,
      is301,
      titleOG,
      descriptionOG,
      imageURLOG,
      imageTypeOG,
    }),
  });
  const data = await response.json();
  return data;
};

export const getUploadedImageURL = (
  identityID: string | null,
  subDomain: string,
  linkPath: string
) =>
  `https://${
    process.env.REACT_APP_OPENGRAGH_LINKS_BASEURL
  }/assets/${identityID}/${subDomain.toLowerCase()}-${linkPath}.jpg?v=${Math.floor(
    Math.random() * 100000
  )}`;

export const getTypeOfURL = (url: string): URLType => {
  if (url.includes("mailto://")) {
    return URLType.Email;
  }

  if (url.includes("tel://")) {
    return URLType.Phone;
  }

  return URLType.WebLink;
};

export const prependProtocolIfNeeded = (urlTyped: string): string => {
  if (/^\+?[1-9][0-9]{7,14}$/.test(urlTyped)) {
    return "tel://" + urlTyped;
  }

  if (
    !urlTyped.includes(".") ||
    urlTyped.startsWith("http:") ||
    urlTyped.startsWith("https:") ||
    urlTyped.startsWith("mailto:") ||
    urlTyped.startsWith("tel:")
  ) {
    return urlTyped;
  }

  if (/^\w+([.-]?\w+)*@\w+/.test(urlTyped)) {
    return "mailto://" + urlTyped;
  }

  return "http://" + urlTyped;
};

export const removeLinkFormModeFromLocalStorage = () => {
  localStorage.removeItem("vStory-Links-Create-Link-Mode");
};

export const setLinkFormModeToLocalStorage = (linkFormMode: LinkFormMode) => {
  localStorage.setItem("vStory-Links-Create-Link-Mode", linkFormMode);
};

export const getLinkFormModeFromLocalStorage = () => {
  return localStorage.getItem("vStory-Links-Create-Link-Mode");
};