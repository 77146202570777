import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export const identifyUserForSentry = (
  name: string,
  email: string
) => {
  if (process.env.NODE_ENV === "production") {
    try {
      Sentry.setUser({ name, email });
    } catch(e) {
      console.error(e);
    }    
  }
};
