import { Storage } from "@aws-amplify/storage";
import { ProgressCB } from "../models";

export const uploadImage = (
  file: File,
  fileName: string,
  contentType: string,
  progressCallback: ProgressCB
) => {
  return Storage.put(fileName, file, {contentType, progressCallback});
};
