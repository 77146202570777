import { useAtom } from "jotai";
import { signOut } from "../../libs/amplifyAuth";
import { progressingAtom, authResetActionAtom } from "../../states";

export const useSignOutBL = (): [signingOut: boolean, signOutFn: Function] => {
  const [, resetAuthAction] = useAtom(authResetActionAtom);
  const [signingOut, setSigningOut] = useAtom(progressingAtom);

  const signOutFn = async () => {
    setSigningOut(true);
    try {
      await signOut();
      resetAuthAction();
    } catch (err) {
      // handle error with signing out
    }
    setSigningOut(false);
  }

  return [signingOut, signOutFn];
}