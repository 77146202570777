import { atom } from "jotai";
import { LinkData, LinkDataResponse } from "../models";

export const loadingAtom = atom<boolean>(false);
export const loadedAtom = atom<boolean>(false);
export const linkDataNextPageLoadingAtom = atom<boolean>(false);
export const linkDataResponseAtom = atom<LinkDataResponse>({
  list: [],
  paginationParams: null,
});
export const linkDataLoadingAtom = atom<boolean>(false);
export const linkDataLoadedAtom = atom<boolean>(false);
export const addToLinksDataListAtom = atom(
  null,
  (get, set, linkData: LinkData) => {
    const linkDataResponse = get(linkDataResponseAtom);
    linkDataResponse.list = [linkData, ...linkDataResponse.list];
    set(linkDataResponseAtom, {...linkDataResponse});
  }
);
export const updateLinksDataListAtom = atom(
  null,
  (
    get,
    set,
    [fullPath, redirectURL, is301, titleOG, descriptionOG, imageURLOG, imageTypeOG]: [
      fullPath: string,
      redirectURL: string,
      is301: boolean,
      titleOG: string,
      descriptionOG: string,
      imageURLOG: string,
      imageTypeOG: string
    ]
  ) => {
    const linkDataResponse = get(linkDataResponseAtom);
    const linkData = linkDataResponse.list.find((linkData: LinkData) => {
      return linkData.fullPath.S === fullPath;
    });
    if (!linkData) {
      return;
    }

    linkData.redirectURL.S = redirectURL;
    linkData.is301.BOOL = is301;
    linkData.titleOG.S = titleOG;
    linkData.descriptionOG.S = descriptionOG;
    linkData.imageURLOG.S = imageURLOG;
    linkData.imageTypeOG.S = imageTypeOG;
    linkData.updatedAt.S = new Date().toISOString();

    linkDataResponse.list = linkDataResponse.list.filter(
      (linkData: LinkData) => {
        return linkData.fullPath.S !== fullPath;
      }
    );
    linkDataResponse.list = [linkData, ...linkDataResponse.list];
    set(linkDataResponseAtom, {...linkDataResponse});
  }
);
export const deleteFromLinksDataListAtom = atom(
  null,
  (get, set, fullPath: string) => {
    const linkDataResponse = get(linkDataResponseAtom);
    linkDataResponse.list = linkDataResponse.list.filter(
      (linkData: LinkData) => {
        return linkData.fullPath.S !== fullPath;
      }
    );
    set(linkDataResponseAtom, {...linkDataResponse});
  }
);
export const updateTagInLinksDataListAtom = atom(
  null,
  (get, set, [fullPath, tag]: [fullPath: string, tag: string]) => {
    const linkDataResponse = get(linkDataResponseAtom);  
    linkDataResponse.list = linkDataResponse.list.map(
      (linkData: LinkData) => {
        if (linkData.fullPath.S === fullPath) {
          linkData.tag = {
            S: tag
          };
        }
        return linkData;
      }
    );
    set(linkDataResponseAtom, {...linkDataResponse});
  }
);
export const deleteTagFromLinksDataListAtom = atom(
  null,
  (get, set, fullPath: string) => {
    const linkDataResponse = get(linkDataResponseAtom);  
    linkDataResponse.list = linkDataResponse.list.map(
      (linkData: LinkData) => {
        if (linkData.fullPath.S === fullPath) {
          delete linkData.tag;
        }
        return linkData;
      }
    );
    set(linkDataResponseAtom, {...linkDataResponse});
  }
);
