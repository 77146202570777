import { atom } from "jotai";
import { LinkFormMode, URLType } from "../models";
import { getTypeOfURL } from "../helpers";
import { isCustomDomain } from "../../../utils";

export const modeAtom = atom<LinkFormMode>(LinkFormMode.CreateWithvStoryCo);
export const activeStepAtom = atom<number>(0);
export const redirectURLAtom = atom<string>("");
export const lastRedirectURLAtom = atom<string>("");
export const is301Atom = atom<boolean>(false);
export const lastIs301Atom = atom<boolean>(false);
export const subDomainAtom = atom<string>("");
export const lastSubDomainAtom = atom<string>("");
export const linkPathAtom = atom<string>("");
export const lastLinkPathAtom = atom<string>("");
export const progressingAtom = atom<boolean>(false);
export const titleOGAtom = atom<string>("");
export const descriptionOGAtom = atom<string>("");
export const imageURLOGAtom = atom<string>("");
export const imageTypeOGAtom = atom<string>("");
export const finishingAtom = atom<boolean>(false);
export const validation1Atom = atom<string>("");
export const validation2Atom = atom<string>("");
export const imgURLToCropAtom = atom<string>("");

export const redirectURLTypeAtom = atom<URLType>((get) =>
  getTypeOfURL(get(redirectURLAtom))
);

export const resetLinkFormActionAtom = atom(null, (_get, set) => {
  set(modeAtom, LinkFormMode.CreateWithvStoryCo);
  set(is301Atom, false);
  set(activeStepAtom, 0);
  set(redirectURLAtom, "");
  set(lastRedirectURLAtom, "");
  set(linkPathAtom, "");
  set(lastLinkPathAtom, "");
  set(subDomainAtom, "");
  set(lastSubDomainAtom, "");
  set(progressingAtom, false);
  set(titleOGAtom, "");
  set(descriptionOGAtom, "");
  set(imageURLOGAtom, "");
  set(imageTypeOGAtom, "");
  set(finishingAtom, false);
  set(validation1Atom, "");
  set(validation2Atom, "");
  set(imgURLToCropAtom, "");
});

export const prepareLinkFormForUpdationAtom = atom(
  null,
  (
    _get,
    set,
    [
      subdomain,
      linkPath,
      redirectURL,
      is301,
      titleOG,
      descriptionOG,
      imageURLOG,
      imageTypeOG,
    ]: [
      subdomain: string,
      linkPath: string,
      redirectURL: string,
      is301: boolean,
      titleOG: string,
      descriptionOG: string,
      imageURLOG: string,
      imageTypeOG: string
    ]
  ) => {
    set(modeAtom, LinkFormMode.Update);
    set(activeStepAtom, 0);
    set(redirectURLAtom, redirectURL);
    set(lastRedirectURLAtom, redirectURL);
    set(is301Atom, is301);
    set(lastIs301Atom, is301);
    set(linkPathAtom, linkPath);
    set(lastLinkPathAtom, linkPath);
    set(subDomainAtom, subdomain);
    set(lastSubDomainAtom, subdomain);
    set(progressingAtom, false);
    set(titleOGAtom, titleOG);
    set(descriptionOGAtom, descriptionOG);
    set(imageURLOGAtom, imageURLOG);
    set(imageTypeOGAtom, imageTypeOG);
    set(finishingAtom, false);
    set(validation1Atom, "");
    set(validation2Atom, "");
    set(imgURLToCropAtom, "");
  }
);

export const prepareLinkFormForDuplicationAtom = atom(
  null,
  (
    _get,
    set,
    [
      subdomain,
      linkPath,
      redirectURL,
      is301,
      titleOG,
      descriptionOG,
      imageURLOG,
      imageTypeOG,
    ]: [
      subdomain: string,
      linkPath: string,
      redirectURL: string,
      is301: boolean,
      titleOG: string,
      descriptionOG: string,
      imageURLOG: string,
      imageTypeOG: string
    ]
  ) => {
    set(
      modeAtom,
      isCustomDomain(subdomain)
        ? LinkFormMode.CreateWithCustomDomain
        : LinkFormMode.CreateWithvStoryCo
    );
    set(redirectURLAtom, redirectURL);
    set(is301Atom, is301);
    set(linkPathAtom, linkPath);
    set(subDomainAtom, subdomain);
    set(progressingAtom, false);
    set(titleOGAtom, titleOG);
    set(descriptionOGAtom, descriptionOG);
    set(imageURLOGAtom, imageURLOG);
    set(imageTypeOGAtom, imageTypeOG);
    set(finishingAtom, false);
    set(validation1Atom, "");
    set(validation2Atom, "");
    set(imgURLToCropAtom, "");
  }
);

export const prepareLinkFormForCustomDomainAtom = atom(
  null,
  (_get, set, subDomain: string) => {
    set(modeAtom, LinkFormMode.CreateWithCustomDomain);
    set(subDomainAtom, subDomain);
  }
);
