import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useAtom } from "jotai";
import { activeStepAtom, modeAtom } from "../states";
import { LinkFormMode } from "../models";

const stepsForCreation = [  
  "Redirect Destination",
  "Design your URL",
  "Social Media Link Preview",
];

const stepsForUpdation = [
  "Redirect Destination",
  "Social Media Link Preview",
];

const StepperStrip = () => {
  const [activeStep] = useAtom(activeStepAtom);
  const [mode] = useAtom(modeAtom);
  let steps = stepsForCreation;
  if (mode === LinkFormMode.Update) {
    steps = stepsForUpdation;
  }

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{ zIndex: 1200, marginTop: "67px" }}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean; className?: string } = {
          className: "has-pointer-cursor",
        };
        return (
          <Step key={label + index} {...stepProps}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperStrip;
