import { useAtom } from "jotai";
import { useState } from "react";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { validateEmail } from "../../../../utils";
import { ERROR_ALREADY_SIGNED_UP } from "../../data";
import { signUp } from "../../libs/amplifyAuth";
import {
  progressingAtom,
  signInEmailAtom,
  signUpNameAtom,
  authUserAtom,
} from "../../states";
import { useSignInBL } from "./signIn";
import { writeSignUpToLocalStorage } from "../../helpers";

export const useSignUpBL = (): [
  name: string,
  email: string,
  signUpValidation: boolean,
  signingUp: boolean,
  signUpFn: Function,
  onNameChange: Function,
  onEmailChange: Function
] => {
  const [name, setName] = useAtom(signUpNameAtom);
  const [email, setEmail] = useAtom(signInEmailAtom);
  const [, setAuthUser] = useAtom(authUserAtom);
  const [signUpValidation, setSignUpValidation] = useState(false);
  const [signingUp, setSigningUp] = useAtom(progressingAtom);
  const [, , , signInFn] = useSignInBL();

  const signUpFn = async () => {
    if (!validateEmail(email)) {
      setSignUpValidation(true);
      return;
    }

    setSigningUp(true);
    try {
      const { user } = await signUp(name, email);
      writeSignUpToLocalStorage();
      setAuthUser(user);
      signInFn();
    } catch (err) {
      handleError(err as Error, signInFn);
    }
    setSigningUp(false);
  };

  const onNameChange = (name: string) => {
    setName(name);
  };

  const onEmailChange = (email: string) => {
    email = email.toLowerCase();
    if (validateEmail(email)) {
      setSignUpValidation(false);
    }
    setEmail(email);
  };

  return [
    name,
    email,
    signUpValidation,
    signingUp,
    signUpFn,
    onNameChange,
    onEmailChange,
  ];
};

const handleError = (error: Error, signInFn: Function) => {
  const message = error.toString();
  if (message === ERROR_ALREADY_SIGNED_UP) {
    signInFn();
  } else {
    alert(ERROR_PREFIX_FOR_ALERT + error);
  }
};
