import { domainStatusAtom } from "../states";
import { useAtom } from "jotai";
import { DomainStatus } from "../models";
import SuccessStep from "./SuccessStep";
import ErrorStep from "./ErrorStep";

const FinalStep = () => {
  const [domainStatus] = useAtom(domainStatusAtom);

  if (domainStatus === DomainStatus.Working) {
    return <SuccessStep />;
  }

  return <ErrorStep />;
};

export default FinalStep;
