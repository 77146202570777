import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useState } from "react";
import { useRemoveDomainBL } from "../hooks/BL";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { DomainData } from "../models";

type RemoveButtonProps = {
  data: DomainData;
};

const RemoveButton: FC<RemoveButtonProps> = ({ data }) => {
  const [removing, removeDomainFn] = useRemoveDomainBL(data);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button
        disabled={removing}
        onClick={() => {
          if (data.linksCount.N !== "0") {
            alert(`There are ${data.linksCount.N} links created using ${data.subDomain.S}. You first have to delete those links.`)
          } else {
            setShowDialog(true);
          }
        }}
        endIcon={
          removing ? <CircularProgress size={14} color="inherit" /> : null
        }
      >
        {removing ? "Removing" : "Remove"}
      </Button>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="remove-domain-alert-title"
        aria-describedby="remove-domain-alert-description"
      >
        <DialogTitle id="remove-domain-alert-title">{"Are you sure ?"}</DialogTitle>
        <DialogContent id="remove-domain-alert-description">
          Should we just remove{" "}
          <Typography color="error" variant="body1" display="inline">
            <strong>{data.subDomain.S}</strong>
          </Typography>{" "}
          ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>No</Button>
          <Button
            onClick={() => {
              setShowDialog(false);
              removeDomainFn();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveButton;
