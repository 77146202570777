import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { URL_APP_VIEW_ALL_LINKS } from "../../../data/URLs";
import PaymentsIcon from "@mui/icons-material/Payments";
import { usePaymentClient, usePaymentProcessBL } from "../hooks/BL";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Currency } from "../models";
import { useAtom } from "jotai";
import { currencyAtom } from "../states";

const PaymentProcess = () => {
  const scriptLoaded = usePaymentClient();
  const [waitingToOpen, startPaymentProcess] = usePaymentProcessBL();
  const [currecy, setCurrency] = useAtom(currencyAtom);
  const Navigate = useNavigate();

  return (
    <form
      className="billing-container"
      onSubmit={(e) => {
        e.preventDefault();
        startPaymentProcess();
      }}
    >
      <Select
        sx={{ mb: 2 }}
        value={currecy}
        onChange={(e) => setCurrency(e.target.value as Currency)}
        defaultValue={Currency.USD}
      >
        <MenuItem value={Currency.USD}>USD ($)</MenuItem>
        <MenuItem value={Currency.INR}>INR (&#8377;)</MenuItem>
      </Select>
      <Typography variant="h3">vStory Links Pro</Typography>
      <Typography
        variant="body1"
        sx={{ p: 2 }}
        textAlign="center"
        component="p"
      >
        {currecy === Currency.INR
          ? "Pay just Rs. 319 per month for unlimited links (paid annually)"
          : "Pay just $3.99 per month for unlimited links (paid annually)"}
      </Typography>
      <Typography
        variant="caption"
        sx={{ px: 2 }}
        textAlign="center"
        component="p"
      >
        {currecy === Currency.INR
          ? "₹319 / months x 12 months = ₹3828 / year (incl. taxes)"
          : "$3.99 / months x 12 months = $47.88 / year"}
      </Typography>
      <Button
        type="submit"
        variant="contained"
        color="success"
        sx={{ m: 2, px: 5, py: 1 }}
        disabled={!scriptLoaded || waitingToOpen}
        startIcon={<PaymentsIcon color="inherit" fontSize="inherit" />}
        endIcon={
          waitingToOpen ? <CircularProgress size={14} color="inherit" /> : null
        }
      >
        {waitingToOpen ? "Proceeding" : "Proceed to Pay"}
      </Button>
      <Typography
        variant="body1"
        sx={{ mt: 5, opacity: 0.2 }}
        textAlign="center"
        whiteSpace="nowrap"
        className="show-desktop-only"
      >
        -------------------------------------------------------- OR
        --------------------------------------------------------
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: 5, opacity: 0.2 }}
        textAlign="center"
        whiteSpace="nowrap"
        className="hide-desktop-only"
      >
        ------------------------------------ OR
        ------------------------------------
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={{ margin: "25px" }}
        onClick={() => {
          Navigate(URL_APP_VIEW_ALL_LINKS);
        }}
      >
        Back To Home
      </Button>
    </form>
  );
};

export default PaymentProcess;
