import Button from "@mui/material/Button";
import "./LinkPreviewForm.css";
import CircularProgress from "@mui/material/CircularProgress";
import ImageCropper from "./ImageCropper";
import { useImageInputBL } from "../hooks/BL/step3";

const ImageInput = () => {
  const [imgURLToCrop, imageReading, onFileChange] = useImageInputBL();
  return (
    <>
      <Button
        disabled={imageReading}
        variant="outlined"
        endIcon={
          imageReading ? <CircularProgress size={14} color="inherit" /> : null
        }
        onClick={clickFileInput}
      >
        {!imageReading ? "Change Image" : "Reading file"}
      </Button>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="opengraph-image-input"
        onChange={onFileChange}
      />
      {imgURLToCrop ? <ImageCropper /> : null}
    </>
  );
};

const clickFileInput = () => {
  document.getElementById("opengraph-image-input")?.click();
};

export default ImageInput;
