import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { URL_APP_ADD_DOMAIN } from "../../../data/URLs";
import { LocalStateAction } from "../../../models";
import Typography from "@mui/material/Typography";

type AddDomainDialogProps = {
  showDialog: boolean;
  setShowDialog: LocalStateAction<boolean>;
};

const AddDomainDialog: FC<AddDomainDialogProps> = ({
  showDialog,
  setShowDialog,
}) => {
  const Navigate = useNavigate();

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      aria-labelledby="delete-link-alert-title"
      aria-describedby="delete-link-alert-description"
    >
      <DialogTitle id="alert-dialog-title">
        Integrate your own domain
      </DialogTitle>
      <DialogContent>
        <Typography variant="caption">
          You can integrate any domain you own, and create links like
          YourDomain.io/something OR link.YourDomain.io/somePath.
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              height: "100%",
              minHeight: "40vh",
              marginTop: "10px",
              borderRadius: "7px",
              border: "none",
              backgroundColor: "#eeeeee",
            }}
            src="https://www.youtube.com/embed/WK7NyiO5h3U"
            title="How to use custom domain name in your trackable links?"            
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDialog(false)}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            setShowDialog(false);
            Navigate(URL_APP_ADD_DOMAIN);
          }}
          autoFocus
        >
          Add your own domain
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDomainDialog;
