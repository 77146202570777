import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Link from "@mui/material/Link";
import {
  linkPathAtom,
  resetLinkFormActionAtom,
  subDomainAtom,
} from "../states";
import { useAtom } from "jotai";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { URL_APP_VIEW_ALL_LINKS } from "../../../data/URLs";
import { makeAURL } from "../../../utils";

const SuccessStep = () => {
  const [subDomain] = useAtom(subDomainAtom);
  const [linkPath] = useAtom(linkPathAtom);
  const [, resetLinkFormAction] = useAtom(resetLinkFormActionAtom);
  const Navigate = useNavigate();

  return (
    <>
      <CheckCircleIcon sx={{ fontSize: 120 }} color="success" />
      <Typography variant="h2">It's Ready!</Typography>
      <Typography variant="body1">
        You can take your link out for a test drive now
      </Typography>
      <Link href={makeAURL(subDomain, linkPath)} target="_blank">
        <strong>{makeAURL(subDomain, linkPath)}</strong>
      </Link>
      <Button
        variant="outlined"
        color="primary"
        sx={{ margin: "25px" }}
        onClick={() => {
          resetLinkFormAction(null);
          Navigate(URL_APP_VIEW_ALL_LINKS);
        }}
      >
        Back To Home
      </Button>
    </>
  );
};

export default SuccessStep;
