import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { URL_APP_CREATE_NEW_LINK, URL_APP_UPDATE_NEW_LINK } from "../../../data/URLs";
import { prepareLinkFormForCustomDomainAtom, prepareLinkFormForDuplicationAtom, prepareLinkFormForUpdationAtom, subDomainAtom } from "../states";
import { LinkFormMode } from "../models";
import { getLinkFormModeFromLocalStorage, removeLinkFormModeFromLocalStorage } from "../helpers";
import { useAuthFeature } from "../../Auth";
import { isCustomDomain } from "../../../utils";

export const useLinkFormForUpdation = () => {
  const [, prepareLinkFormForUpdation] = useAtom(
    prepareLinkFormForUpdationAtom
  );
  const [, userInfo] = useAuthFeature();

  const Navigate = useNavigate();
  return (
    subdomain: string,
    linkPath: string,
    redirectURL: string,
    is301: boolean,
    titleOG: string,
    descriptionOG: string,
    imageURLOG: string,
    imageTypeOG: string
  ) => {
    if (userInfo?.disabled) {
      alert(userInfo?.disabled);
    } else {
      prepareLinkFormForUpdation([
        subdomain,
        linkPath,
        redirectURL,
        is301,
        titleOG,
        descriptionOG,
        imageURLOG,
        imageTypeOG,
      ]);
      Navigate(URL_APP_UPDATE_NEW_LINK);  
    }
  };
};

export const useLinkFormForCustomDomain = () => {
  const [, prepareLinkFormForCustomDomain] = useAtom(
    prepareLinkFormForCustomDomainAtom
  );
  const Navigate = useNavigate();
  const [, userInfo] = useAuthFeature();

  return (domainName: string) => {
    if (userInfo?.disabled) {
      alert(userInfo?.disabled);
    } else {
      const subdomain = `customDomain-_-@${domainName}`;
      prepareLinkFormForCustomDomain(subdomain);
      Navigate(URL_APP_CREATE_NEW_LINK);  
    }
  };
};

export const useCreateLinkFormMode = (domainNameList: string[]) => {
  const [, prepareLinkFormForCustomDomain] = useAtom(
    prepareLinkFormForCustomDomainAtom
  );
  const [subDomain] = useAtom(subDomainAtom);

  if (domainNameList.length) {
    const localstorageValue = getLinkFormModeFromLocalStorage();
    if (localstorageValue !== LinkFormMode.CreateWithvStoryCo) {      
      if (isCustomDomain(subDomain)) {
        removeLinkFormModeFromLocalStorage();
      } else {
        const subdomainDefault = `customDomain-_-@${domainNameList[0]}`;
        prepareLinkFormForCustomDomain(subdomainDefault);
        removeLinkFormModeFromLocalStorage();
      }
    }
  }
};

export const useLinkFormForDuplication = () => {
  const [, prepareLinkFormForDuplication] = useAtom(
    prepareLinkFormForDuplicationAtom
  );
  const [, userInfo] = useAuthFeature();

  return (
    subdomain: string,
    linkPath: string,
    redirectURL: string,
    is301: boolean,
    titleOG: string,
    descriptionOG: string,
    imageURLOG: string,
    imageTypeOG: string
  ) => {
    if (userInfo?.disabled) {
      alert(userInfo?.disabled);
    } else {
      prepareLinkFormForDuplication([
        subdomain,
        linkPath,
        redirectURL,
        is301,
        titleOG,
        descriptionOG,
        imageURLOG,
        imageTypeOG,
      ]);
    }
  };
};