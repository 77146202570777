import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import Auth from "./features/Auth";
import AuthSignIn from "./features/Auth/pages/AuthSignIn";
import AuthSignUp from "./features/Auth/pages/AuthSignUp";
import AuthVerifyOTP from "./features/Auth/pages/AuthVerifyOTP";
import AppHome from "./pages/Home";
import LinksList from "./features/LinksList";
import LinkFormUX from "./features/LinkForm";
import {
  URL_APP_ADD_DOMAIN,
  URL_APP_BILLING,
  URL_APP_BILLING_SUCCESS,
  URL_APP_CREATE_NEW_LINK,
  URL_APP_HOME,
  URL_APP_UPDATE_NEW_LINK,
  URL_APP_VIEW_ALL_DOMAINS,
  URL_APP_VIEW_ALL_LINKS,
  URL_SIGNIN,
  URL_SIGNUP,
  URL_VERIFY_OTP,
} from "./data/URLs";
import Billing, { BillingForPaidUser } from "./features/Billing";
import DomainFormUX from "./features/DomainForm";
import DomainsList from "./features/DomainsList";

const routes = [
  {
    path: "/", // user can't ever go here as per cloudfront settings
    element: (
      <Auth>
        <Outlet />
      </Auth>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: URL_SIGNUP,
        element: <AuthSignUp />,
      },
      {
        path: URL_SIGNIN,
        element: <AuthSignIn />,
      },
      {
        path: URL_VERIFY_OTP,
        element: <AuthVerifyOTP />,
      },      
      {
        path: URL_APP_VIEW_ALL_LINKS,
        element: (
          <AppHome>
            <LinksList />
          </AppHome>
        ),
      },
      {
        path: URL_APP_CREATE_NEW_LINK,
        element: (
          <AppHome>
            <LinkFormUX />
          </AppHome>
        ),
      },
      {
        path: URL_APP_UPDATE_NEW_LINK,
        element: (
          <AppHome>
            <LinkFormUX />
          </AppHome>
        ),
      },
      {
        path: URL_APP_ADD_DOMAIN,
        element: (
          <AppHome>
            <DomainFormUX />
          </AppHome>
        ),
      },
      {
        path: URL_APP_VIEW_ALL_DOMAINS,
        element: (
          <AppHome>
            <DomainsList />
          </AppHome>
        ),
      },
      {
        path: URL_APP_BILLING,
        element: (
          <AppHome>
            <Billing />
          </AppHome>
        ),
      },
      {
        path: URL_APP_BILLING_SUCCESS,
        element: (
          <AppHome>
            <BillingForPaidUser />
          </AppHome>
        ),
      },
    ],
  },
  {
    path: URL_APP_HOME,
    element: <Navigate to={URL_APP_VIEW_ALL_LINKS} replace />,
  },
];

const router = createBrowserRouter(routes);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
