import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { logoIconURL } from "../data";
import Typography from "@mui/material/Typography";
import { BillingButton } from "../features/Billing";
import MenuButton from "./MenuButton";
import {AuthSignout} from "../features/Auth";
import { MyLinksListButton } from "../features/LinksList";
import { MyDomainsListButton } from "../features/DomainsList";
import "./AppHeader.css";

const AppHeader = () => {
  return (
    <AppBar position="fixed" color="inherit" style={{ boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }}>
            <img src={logoIconURL} alt="" height="24" />
            &nbsp;
            <Typography>
              <strong>vStory Links</strong>
            </Typography>
          </IconButton>
          <Typography sx={{ flexGrow: 1 }}></Typography>
          <MyLinksListButton className="show-desktop-only" mobile={false} />
          <p>&nbsp;&nbsp;&nbsp;</p>
          <MyDomainsListButton className="show-desktop-only" mobile={false} />
          <p>&nbsp;&nbsp;&nbsp;</p>        
          <BillingButton className="show-desktop-only" hightlight={true} />
          <BillingButton className="hide-desktop-only" hightlight={false} />
          <p>&nbsp;&nbsp;&nbsp;</p>
          <AuthSignout className="show-desktop-only" mobile={false} />
          <MenuButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppHeader;
