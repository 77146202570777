import { FC } from "react";
import Chip from "@mui/material/Chip";

type AnalyticsDialogTagChipProps = {
  tag?: string;
};

const AnalyticsDialogTagChip: FC<AnalyticsDialogTagChipProps> = ({ tag }) => {
  if (!tag) {
    return null;
  }

  return (
    <Chip
      color="success"
      size="small"
      label={tag}
    />
  );
};

export default AnalyticsDialogTagChip;
