import Typography from "@mui/material/Typography"
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import LinearProgress from "@mui/material/LinearProgress";
import { FC } from "react";

const WaitingToVerify: FC<{domainName: string}> = ({domainName}) => {
  return (
    <>
          <Typography variant="body1" textAlign="center">
            Waiting for DNS records of {domainName} to reflect into our systems
          </Typography>
          <EmojiFoodBeverageIcon
            sx={{ fontSize: "50px", padding: "10px" }}
            color="success"
          />
          <Typography variant="body2" textAlign="center" sx={{ padding: "0 15px" }}>
            Go grab coffee and come back! You can even safely close now if you
            want
          </Typography>
          <div
            style={{ maxWidth: "650px", width: "100%", padding: "5px 15px" }}
          >
            <LinearProgress
              sx={{ width: "100%", height: "20px", borderRadius: "20px" }}
              color="warning"
            />
          </div>
          <Typography
            variant="caption"
            textAlign="center"
            style={{ maxWidth: "500px", padding: "0 10px" }}
          >
            Depending on the TTL you've set, it may take about 2 minutes, to all
            the way upto 24 hours for us to see your DNS records
          </Typography>
        </>
  )
}

export default WaitingToVerify;