import { ColorString, DomainStatus } from "../models";

export const listDomains = async (
  accessToken: string | null,
) => {
  try {
    var url = new URL(
      process.env.REACT_APP_API_GATEWAY_BASEURL + "/domains/list"
    );
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const removeDomain = async (
  accessToken: string | null,
  domainName: string
) => {
  var url = new URL(
    process.env.REACT_APP_API_GATEWAY_BASEURL + "/domain/remove"
  );
  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domainName: domainName.toLowerCase(),
    }),
  });
  const data = await response.json();
  return data;
};

export const getStatusLabel = (status: DomainStatus): string => {
  switch(status) {
    case DomainStatus.WaitingToAddDNS:
      return "Set-up is incomplete";
    
    case DomainStatus.WaitingToVerifyDNS:
      return "Waiting to verify DNS";
    
    case DomainStatus.WaitingToInstall:
      return "DNS verified! Setting up...";
    
    case DomainStatus.Working:
        return "Sucessfully Integrated";

    default:
        return "Some error, fixing it...";    
  }
};

export const getStatusColor = (status: DomainStatus): ColorString => {
  switch(status) {
    case DomainStatus.WaitingToAddDNS:
      return "warning";
    
    case DomainStatus.WaitingToVerifyDNS:
      return "info";
    
    case DomainStatus.WaitingToInstall:
      return "success";
    
    case DomainStatus.Working:
      return "success";

    default:
        return "error";    
  }
}