import { useAtom } from "jotai";
import { AtomAction } from "../../../../models";
import { useAuthFeature } from "../../../Auth";
import { getOpenGraphData, isARedirectLoop } from "../../helpers";
import { URLType } from "../../models";
import {
  activeStepAtom,
  lastRedirectURLAtom,
  descriptionOGAtom,
  imageTypeOGAtom,
  imageURLOGAtom,
  titleOGAtom,
  linkPathAtom,
  progressingAtom,
  redirectURLAtom,
  validation2Atom,
  subDomainAtom,
  resetLinkFormActionAtom,
  lastIs301Atom,
  is301Atom,
  redirectURLTypeAtom,
} from "../../states";

export const useStep1BL = (): [
  validation: string,
  progressing: boolean,
  submitStep1: Function,
  resetLinkFormAction: AtomAction<null>
] => {
  const [,,getAuthToken] = useAuthFeature();
  const [lastRedirectURL, setLastRedirectURL] = useAtom(lastRedirectURLAtom);
  const [redirectURL] = useAtom(redirectURLAtom);
  const [progressing, setProgressing] = useAtom(progressingAtom);
  const [subDomain] = useAtom(subDomainAtom);
  const [linkPath] = useAtom(linkPathAtom);
  const [lastIs301, setLastIs301] = useAtom(lastIs301Atom);
  const [is301] = useAtom(is301Atom);
  const [redirectURLType] = useAtom(redirectURLTypeAtom);
  const [, setTitleOG] = useAtom(titleOGAtom);
  const [, setDescriptionOG] = useAtom(descriptionOGAtom);
  const [, setImageURLOG] = useAtom(imageURLOGAtom);
  const [, setImageTypeOG] = useAtom(imageTypeOGAtom);
  const [, setActiveStep] = useAtom(activeStepAtom);
  const [validation, setValidation] = useAtom(validation2Atom);
  const [, resetLinkFormAction] = useAtom(resetLinkFormActionAtom);

  const setOpenGraphData = (data: any) => {
    if (data?.result?.ogTitle) {
      setTitleOG(data?.result?.ogTitle);
    } else {
      setTitleOG("");
    }

    if (data?.result?.ogDescription) {
      setDescriptionOG(data?.result?.ogDescription);
    } else {
      setDescriptionOG("");
    }

    if (data?.result?.ogImage?.url) {
      let imageURL: string;
      if (data?.result?.ogUrl) {
        imageURL = new URL(data?.result?.ogImage?.url, data?.result?.ogUrl)
          .href;
      } else {
        imageURL = new URL(data?.result?.ogImage?.url).href;
      }
      setImageURLOG(imageURL);
    } else {
      setImageURLOG("");
    }

    if (data?.result?.ogImage?.type) {
      let imageType: string = data?.result?.ogImage?.type;
      if (!imageType.startsWith("image/")) {
        imageType = "image/" + imageType;
      }
      setImageTypeOG(imageType);
    } else {
      setImageTypeOG("");
    }
  };

  const handleLoadingOpenGraphData = async () => {
    try {
      const accessToken = await getAuthToken();
      const data = await getOpenGraphData(accessToken, redirectURL);
      console.log("opengraph", data.result);

      if (data?.error?.result?.error === "Invalid URL") {
        setValidation("The link you entered, is not valid");
        return;
      }

      if (data?.error === "This redirect destination is not allowed") {
        setValidation("This redirect destination is not allowed");
        return;
      }

      setOpenGraphData(data);
    } catch (err) {
      // handle not being able to load open graph data
    }

    setLastRedirectURL(redirectURL);
    setLastIs301(is301);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const submitStep1 = async () => {
    if (redirectURL === "") {
      setValidation("Redirect Destination can't be empty");
      return;
    }

    if (isARedirectLoop(subDomain, linkPath, redirectURL)) {
      setValidation("Redirect Destination can't be same as original link");
      return;
    }

    if (lastRedirectURL === redirectURL && lastIs301 === is301) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    if (redirectURLType === URLType.Email) {
      setLastRedirectURL(redirectURL);
      setLastIs301(is301);
      setTitleOG("Click To Email");
      setDescriptionOG("");
      setImageURLOG("");
      setImageTypeOG("");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }
    if (redirectURLType === URLType.Phone) {
      setLastRedirectURL(redirectURL);
      setLastIs301(is301);
      setTitleOG("Click To Call");
      setDescriptionOG("");
      setImageURLOG("");
      setImageTypeOG("");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }
    if (redirectURLType === URLType.WebLink) {
      setProgressing(true);
      await handleLoadingOpenGraphData();
      setProgressing(false);
    }
  };

  return [
    validation,
    progressing,
    submitStep1,
    resetLinkFormAction,
  ];
};
