import { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { URL_SIGNIN } from "../data/URLs";
import { useAuthFeature } from "../features/Auth";
import AppHeader from "../components/AppHeader";
import "./Home.css";

const AppHpme: FC<PropsWithChildren> = (props) => {
  const navigate = useNavigate();
  const [isAuthenticated] = useAuthFeature();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(URL_SIGNIN);
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <AppHeader />
      {props.children}
    </>
  );
};

export default AppHpme;
