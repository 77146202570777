import Button from "@mui/material/Button";
import { FC } from "react";
import { ColorString, DomainData } from "../models";
import { useDomainFormToContinue } from "../../DomainForm";

const ContinueToDomainFormButton: FC<{data: DomainData, color: ColorString, label: string}> = ({data, color, label}) => {
  const proceedToUpdateLink = useDomainFormToContinue();
  return <Button color={color} onClick={()=>{
    proceedToUpdateLink(data);
  }}>{label}</Button>;
};

export default ContinueToDomainFormButton;