import { FC } from "react";
import { LinkData } from "../models";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { LocalStateAction } from "../../../models";
import CircularProgress from "@mui/material/CircularProgress";
import { useDeleteTagBL } from "../hooks/BL";

type DeleteTagDialogProps = {
  data: LinkData;
  showDialog: boolean;
  setShowDialog: LocalStateAction<boolean>;
};

const DeleteTagDialog: FC<DeleteTagDialogProps> = ({
  data,
  showDialog,
  setShowDialog,
}) => {
  const [deleting, onDialogClose, onDialogSubmit] = useDeleteTagBL(
    data,
    setShowDialog
  );

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => onDialogClose()}
      aria-labelledby="tag-link-alert-title"
      aria-describedby="tag-link-alert-description"
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          onDialogSubmit();
        }}
      >
        <DialogTitle id="alert-dialog-title">Delete the tag</DialogTitle>
        <DialogContent id="alert-dialog-description">
          You're about to delete the tag <strong>{`${data?.tag?.S}`}</strong>
          &nbsp;from&nbsp;
          <i>{`${data.subDomain.S}.vStory.co/${data.linkPath.S}`}</i>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => onDialogClose()}>Cancel</Button>
          <Button
            disabled={deleting}
            type="submit"
            endIcon={
              deleting ? <CircularProgress size={14} color="inherit" /> : null
            }
          >
            {deleting ? "Deleting" : "Delete"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteTagDialog;
