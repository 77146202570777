import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import Alert from "@mui/material/Alert";
import { signInEmailAtom } from "../states";
import Timer from "../components/Timer";
import SubmitButton from "../../../components/SubmitButton";
import {
  useNavigateHomeIfLoggedIn,
  useNavigateToSignInIfNoOTP,
} from "../hooks";
import { useOTPVerificationBL } from "../hooks/BL/verifyOTP";

const AuthVerifyOTP = () => {
  useNavigateHomeIfLoggedIn();
  useNavigateToSignInIfNoOTP();
  const [
    OTP,
    OTPValidation,
    OTPVerifying,
    verifyOTPFn,
    onOTPChange,
    onOTPExpire,
  ] = useOTPVerificationBL();
  const [authSignInEmail] = useAtom(signInEmailAtom);

  return (
    <form
      className="is-full-size is-centered"
      onSubmit={(e) => {
        e.preventDefault();
        verifyOTPFn();
      }}
    >
      <Typography variant="h5" component="h5">
        Check your Email Inbox
      </Typography>
      <Typography variant="body1" color="green">
        A secret login code has been sent to
      </Typography>
      <Typography variant="body1" color="green"><strong>{authSignInEmail}</strong></Typography>
      <br />
      <TextField
        placeholder="Enter secret code"
        variant="standard"
        className="is-centered-item"
        inputProps={{className: "data-hj-allow"}}
        onChange={(e) => onOTPChange(e.target.value)}
        autoFocus={true}
        value={OTP}
      />
      <Typography variant="caption" className="is-centered-item">
        Please enter before <Timer onComplete={() => onOTPExpire()} />
      </Typography>
      {OTPValidation ? <br /> : null}
      {OTPValidation ? (
        <Alert
          severity="warning"
          variant="outlined"
          className="is-centered-item"
        >
          The code you entered, is not valid
        </Alert>
      ) : null}
      <br />
      <SubmitButton
        showProgress={OTPVerifying}
        progressText="Logging you in"
        defaultText="Log In"
      />
    </form>
  );
};

export default AuthVerifyOTP;
