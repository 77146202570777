import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useRouteError } from "react-router-dom";
import "./ErrorPage.css";
import { RouteError } from "../models";

export default function ErrorPage() {
  const error = useRouteError() as RouteError;

  return (
    <Paper elevation={0} className="error-page">
      <Typography variant="h1" component="h1">
        Oops!
      </Typography>
      <Typography variant="body1" component="p">
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant="body1" component="p">
      <i>{error.statusText || error.message}</i>
      </Typography>
    </Paper>
  );
}
