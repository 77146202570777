import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { LocalStateAction } from "../../../models";
import Typography from "@mui/material/Typography";
import URLDesignForm from "./URLDesignForm";
import CustomDomainToggle from "./CustomDomainToggle";
import Alert from "@mui/material/Alert";
import { makeAURL } from "../../../utils";
import { useDuplicateDialogBL } from "../hooks/BL/duplicateDialog";
import { UpgradeDialog } from "../../Billing";
import CircularProgress from "@mui/material/CircularProgress";

type DuplicateDialogProps = {
  showDialog: boolean;
  setShowDialog: LocalStateAction<boolean>;
};

const DuplicateDialog: FC<DuplicateDialogProps> = ({
  showDialog,
  setShowDialog,
}) => {
  const [
    domainNameList,
    subDomain,
    linkPath,
    progressing,
    validation,
    showUpgrade,
    setShowUpgrade,
    submitDuplicateDialog,
    handleClose,
  ] = useDuplicateDialogBL(setShowDialog);

  return (
    <Dialog
      open={showDialog}
      onClose={() => handleClose()}
      aria-labelledby="delete-link-alert-title"
      aria-describedby="delete-link-alert-description"
    >
      <form
        className="is-centered"
        onSubmit={(e) => {
          e.preventDefault();
          submitDuplicateDialog();
        }}
      >
        <DialogTitle id="alert-dialog-title">Duplicate this link</DialogTitle>
        <DialogContent>
          <Typography variant="caption" textAlign="center" component="p">
            If you need to post the same link to two seperate places, but need
            to track analytics of them seperately, then duplicating the links is
            the way to go
          </Typography>
          <br />
          <div className="is-centered">
            <URLDesignForm domainNameList={domainNameList} />
            {subDomain && !validation ? (
              <Typography
                variant="caption"
                textAlign="center"
                color="green"
                fontWeight="800"
                style={{ maxWidth: "500px", padding: "0 10px" }}
              >
                {makeAURL(subDomain, linkPath)}
              </Typography>
            ) : null}
            {validation ? (
              <Alert
                severity="warning"
                variant="outlined"
                className="is-centered-item"
              >
                {validation}
              </Alert>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={progressing} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={progressing}
            endIcon={
              progressing ? (
                <CircularProgress size={14} color="inherit" />
              ) : null
            }
          >
            {progressing ? "Duplicating" : "Duplicate"}&nbsp;
          </Button>
        </DialogActions>
        <CustomDomainToggle domainNameList={domainNameList} />
        <br/>
        {showUpgrade ? (
          <UpgradeDialog
            showDialog={showUpgrade}
            setShowDialog={setShowUpgrade}
          />
        ) : null}
      </form>
    </Dialog>
  );
};

export default DuplicateDialog;
