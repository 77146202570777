import CircularProgress from "@mui/material/CircularProgress";
import { useSignOutBL } from "../hooks/BL/signOut";
import { FC } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ListItemText from "@mui/material/ListItemText";

type AuthSignoutProps = {
  className?: string; 
  mobile: boolean;
}

const AuthSignout: FC<AuthSignoutProps> = ({
  className,
  mobile,
}) => {
  const [signingOut, signOutFn] = useSignOutBL();

  if (mobile) {
    return (
      <MenuItem
        sx={{ p: 2 }}
        onClick={() => {
          signOutFn();
        }}
      >
        <ListItemIcon>
          {signingOut ? (
            <CircularProgress size={14} color="primary" />
          ) : (
            <PowerSettingsNewIcon />
          )}
        </ListItemIcon>
        <ListItemText>{signingOut ? "Leaving" : "Log Out"}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Button
      className={className}
      variant="text"
      color="inherit"
      disabled={signingOut}
      onClick={() => {
        signOutFn();
      }}
    >
      {signingOut ? (
        <>
          Leaving&nbsp;
          <CircularProgress size={14} color="primary" />
        </>
      ) : (
        <strong>Log out</strong>
      )}
    </Button>
  );
};

export default AuthSignout;
