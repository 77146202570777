import { FC, useState } from "react";
import { LinkData } from "../models";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import UpdateTagDialog from "./UpdateTagDialog";
import DeleteTagDialog from "./DeleteTagDialog";
import QRCodeDialog from "./QRCodeDialog";
import { DuplicateDialog, useLinkFormForDuplication } from "../../LinkForm";

type MoreButtonProps = {
  data: LinkData;
};

const MoreButton: FC<MoreButtonProps> = ({ data }) => {
  const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  const [showUpdateTagDialog, setShowUpdateTagDialog] = useState(false);
  const [showDeleteTagDialog, setShowDeleteTagDialog] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const prepareForLinkDuplication = useLinkFormForDuplication();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openQRCodeDialog = () => {
    setShowQRCodeDialog(true);
    handleClose();
  };

  const openUpdateTagDialog = () => {
    setShowUpdateTagDialog(true);
    handleClose();
  };

  const openDeleteTagDialog = () => {
    setShowDeleteTagDialog(true);
    handleClose();
  };

  const openDuplicateDialog = () => {
    prepareForLinkDuplication(
      data.subDomain.S,
      data.linkPath.S,
      data.redirectURL.S,
      data.is301.BOOL,
      data.titleOG.S,
      data.descriptionOG.S,
      data.imageURLOG.S,
      data.imageTypeOG.S
    );
    setShowDuplicateDialog(true);
    handleClose();
  };

  return (
    <>
      <IconButton
        id="link-card-menu-button"
        aria-controls={open ? "link-card-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="link-card-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "link-card-menu-button",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem onClick={openQRCodeDialog}>Download QR Code</MenuItem>
        {data.tag && data.tag.S ? (
          <MenuItem onClick={openUpdateTagDialog}>Edit the tag</MenuItem>
        ) : (
          <MenuItem onClick={openUpdateTagDialog}>Add a tag</MenuItem>
        )}
        {data.tag && data.tag.S ? (
          <MenuItem onClick={openDeleteTagDialog}>Delete the tag</MenuItem>
        ) : null}
        <MenuItem onClick={openDuplicateDialog}>Duplicate link</MenuItem>
      </Menu>
      <QRCodeDialog
        data={data}
        showDialog={showQRCodeDialog}
        setShowDialog={setShowQRCodeDialog}
      />
      <UpdateTagDialog
        data={data}
        showDialog={showUpdateTagDialog}
        setShowDialog={setShowUpdateTagDialog}
      />
      <DeleteTagDialog
        data={data}
        showDialog={showDeleteTagDialog}
        setShowDialog={setShowDeleteTagDialog}
      />
      <DuplicateDialog
        showDialog={showDuplicateDialog}
        setShowDialog={setShowDuplicateDialog}
      />
    </>
  );
};

export default MoreButton;
