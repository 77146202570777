import { atom } from "jotai";
import {
  PREFERS_SYSTEM_THEME_MODE,
  LIGHT_THEME_MODE,
} from "../data/theme";

export const themeModePreferenceAtom = atom(PREFERS_SYSTEM_THEME_MODE);
export const systemPrefersDarkModeAtom = atom(false);
export const themeModeAtom = atom(() => {
  return LIGHT_THEME_MODE;

  // if (get(themeModePreferenceAtom) === PREFERS_DARK_THEME_MODE) {
  //   return DARK_THEME_MODE;
  // }

  // if (get(themeModePreferenceAtom) === PREFERS_LIGHT_THEME_MODE) {
  //   return LIGHT_THEME_MODE;
  // }

  // return get(systemPrefersDarkModeAtom) ? DARK_THEME_MODE : LIGHT_THEME_MODE;
});
