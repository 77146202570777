import Link from "@mui/material/Link";
import { useAtom } from "jotai";
import { modeAtom, subDomainAtom } from "../states";
import "./CustomDomainToggle.css";
import { LinkFormMode } from "../models";
import { makeAURL } from "../../../utils";
import { AddDomainDialog } from "../../DomainForm";
import { FC, useState } from "react";
import { setLinkFormModeToLocalStorage } from "../helpers";

type CustomDomainToggleProps = {
  domainNameList: string[]
}

const CustomDomainToggle: FC<CustomDomainToggleProps> = ({domainNameList}) => {
  const [mode, setMode] = useAtom(modeAtom);
  const [subDomain, setSubDomain] = useAtom(subDomainAtom);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      {/* <hr className="custom-domain-toggle-seperator" /> */}
      <Link
        color="inherit"
        underline="always"
        textAlign="center"
        className="custom-domain-toggle has-pointer-cursor"
        onClick={() => {
          if (mode === LinkFormMode.CreateWithvStoryCo) {
            if (domainNameList.length === 0) {
              setShowDialog(true);
            } else {
              setMode(LinkFormMode.CreateWithCustomDomain);
              setSubDomain(`customDomain-_-@${domainNameList[0]}`);
              setLinkFormModeToLocalStorage(LinkFormMode.CreateWithCustomDomain);
            }
          }

          if (mode === LinkFormMode.CreateWithCustomDomain) {
            setMode(LinkFormMode.CreateWithvStoryCo);
            setSubDomain("");
            setLinkFormModeToLocalStorage(LinkFormMode.CreateWithvStoryCo);
          }
        }}
      >
        <LinkContent mode={mode} subDomain={subDomain} />
      </Link>
      <AddDomainDialog showDialog={showDialog} setShowDialog={setShowDialog} />
    </>
  );
};

const LinkContent: FC<{ mode: LinkFormMode; subDomain: string }> = ({
  mode,
  subDomain,
}) => {
  if (mode === LinkFormMode.CreateWithCustomDomain) {
    return <>Use vstory.co domain instead</>;
  }

  if (subDomain) {
    const currentDoaminName = makeAURL(subDomain, "")
      .split("https://")
      .join("");
    return (
      <>
        <Highlighter />{" "}
        {`Use your own domain instead of ${currentDoaminName}`}
      </>
    );
  }

  return (
    <>
      <Highlighter /> Use your own domain instead of vStory.co
    </>
  );
};

const Highlighter = () => {
  return (
    <span className="custom-domain-toggle-highlighter">
      <strong>NEW</strong>
    </span>
  );
};

export default CustomDomainToggle;
