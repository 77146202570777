import "./index.css";
import BillingForPaidUser from "../components/BillingForPaidUser";
import PaymentLoading from "../components/PaymentLoading";
import BillingForUnpaidUser from "../components/BillingForUnpaidUser";
import { usePaymentNeededBL } from "../hooks/BL";

const Billing = () => {
  const [paymentNeeded, userInfo] = usePaymentNeededBL();

  if (!userInfo) {
    return <PaymentLoading />;
  }

  if (paymentNeeded) {
    return <BillingForUnpaidUser />
  }

  return <BillingForPaidUser />;
};

export default Billing;
