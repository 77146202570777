import { FC } from "react";
import Chip from "@mui/material/Chip";
import { DomainData } from "../models";
import { getStatusColor, getStatusLabel } from "../helpers";

type DomainCardStatusChipProps = {
  data: DomainData;
};

const DomainCardStatusChip: FC<DomainCardStatusChipProps> = ({ data }) => {
  if (data.domainStatus) {
    return (
      <Chip
        className="domain-card-status-chip"
        color={getStatusColor(data.domainStatus.S)}
        size="small"
        label={getStatusLabel(data.domainStatus.S)}
      />
    );
  }
  return null;
};

export default DomainCardStatusChip;
