export type DomainData = {
  domainName: { S: string };
  subDomain: { S: string };
  domainStatus: { S: DomainStatus };
  ipAddress: { S: string };
  userID: { S: string };
  linksCount: { N: string };
  DNSVerifiedAt?: { S: string };
  DNSAddedAt: { S: string };
};

export type ColorString =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

export enum DomainStatus {
  WaitingToAddDNS = "WaitingToAddDNS",
  WaitingToVerifyDNS = "WaitingToVerifyDNS",
  WaitingToInstall = "WaitingToInstall",
  Working = "Working",
  Error = "Error",
}

export type DomainsDataResponse = {
  list: DomainData[];
};
