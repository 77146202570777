import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAtom } from "jotai";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./Theme.css";
import { systemPrefersDarkModeAtom, themeModeAtom } from "../states/theme";
import { useEffect, FC, PropsWithChildren } from "react";
import { PaletteMode } from "@mui/material";

const Theme: FC<PropsWithChildren> = (props) => {
  const [, setSystemPrefersDarkMode] = useAtom(systemPrefersDarkModeAtom);
  const [themeMode] = useAtom(themeModeAtom);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(()=>{
    setSystemPrefersDarkMode(prefersDarkMode);
  }, [prefersDarkMode, setSystemPrefersDarkMode]);


  const theme = createTheme({
    palette: {
      mode: themeMode as PaletteMode,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

export default Theme;
