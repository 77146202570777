import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useState } from "react";
import { useDeleteLinkBL } from "../hooks/BL";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { LinkData } from "../models";
import { makeAURL } from "../../../utils";

type DeleteButtonProps = {
  data: LinkData;
};

const DeleteButton: FC<DeleteButtonProps> = ({ data }) => {
  const [deleting, deleteLinkFn] = useDeleteLinkBL(data);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button
        disabled={deleting}
        onClick={() => setShowDialog(true)}
        endIcon={
          deleting ? <CircularProgress size={14} color="inherit" /> : null
        }
      >
        {deleting ? "Deleting" : "Delete"}
      </Button>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="delete-link-alert-title"
        aria-describedby="delete-link-alert-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>
        <DialogContent id="alert-dialog-description">
          Should we just delete{" "}
          <Typography color="error" variant="body1" display="inline">
            <strong>{makeAURL(data.subDomain.S, data.linkPath.S).split("https://").join("")}</strong>
          </Typography>{" "}
          ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>No</Button>
          <Button
            onClick={() => {
              setShowDialog(false);
              deleteLinkFn();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteButton;
