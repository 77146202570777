import { FC } from "react";
import Button from "@mui/material/Button";
import "./ImageUploader.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useImageCropAndUploadBL } from "../hooks/BL/step3";

type ImageUploaderProps = {
  rotation: number;
  croppedAreaPixels: any;
};

const ImageUploader: FC<ImageUploaderProps> = ({
  rotation,
  croppedAreaPixels,
}) => {
  const [imageUploadProgress, imageUploading, cropAndUploadImage] =
    useImageCropAndUploadBL(rotation, croppedAreaPixels);

  return (
    <Button
      onClick={() => cropAndUploadImage()}
      variant="contained"
      color="primary"
      disabled={imageUploading}
      className="og-image-upload-button"
      endIcon={
        imageUploading ? <CircularProgress size={14} color="inherit" /> : null
      }
    >
      {imageUploading ? `Uploading (${imageUploadProgress}%)` : "Finalize"}
    </Button>
  );
};

export default ImageUploader;
