import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { URL_APP_ADD_DOMAIN } from "../../../data/URLs";
import { useDomainRefresh, useLoadDomainsListBL } from "../hooks";
import { DomainData } from "../models";
import EmptyScreen from "../components/EmptyScreen";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DomainCard from "../components/DomainCard";
import { useEffect } from "react";

const DomainsList = () => {
  const [domainsDataResponse, loading, loaded] = useLoadDomainsListBL();
  const Navigate = useNavigate();
  useDomainRefresh();

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Paper
        style={{
          position: "fixed",
          top: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          zIndex: 100,
        }}
        variant="outlined"
      >
        <Container maxWidth="xl">
          <Typography variant="h6" sx={{ my: 2 }} align="center">
            My Domains&nbsp;&nbsp;(
            <Button onClick={() => Navigate(URL_APP_ADD_DOMAIN)} variant="text">
              Add New Domain
            </Button>
            )
          </Typography>
        </Container>
        {loading ? <LinearProgress sx={{ width: "100%" }} /> : null}
      </Paper>
      <Container maxWidth="xl" sx={{ marginTop: "150px" }}>
        {domainsDataResponse?.list?.length > 0 ? (
          <Grid container spacing={2} justifyContent="center">
            {domainsDataResponse.list.map((data: DomainData) => {
              return (
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={"domain-card-" + data.domainName.S}
                >
                  <DomainCard data={data} />
                </Grid>
              );
            })}
          </Grid>
        ) : !loading && loaded ? (
          <EmptyScreen />
        ) : null}
      </Container>
    </>
  );
};

export default DomainsList;
