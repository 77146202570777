import Step1 from "./Step1";
import { useAtom } from "jotai";
import { activeStepAtom } from "../states";
import Step2 from "./Step2";
import Step3 from "./Step3";
import FinalStep from "../components/FinalStep";
import StepperStrip from "../components/StepperStrip";

const DomainFormUX = () => {
  const [activeStep] = useAtom(activeStepAtom);
  const className = "container-topped";

  return (
    <div className={`is-centered ${className}`}>
      {activeStep < 3 ? <StepperStrip /> : null}
      <br />
      {activeStep === 0 ? <Step1 /> : null}
      {activeStep === 1 ? <Step2 /> : null}
      {activeStep === 2 ? <Step3 /> : null}
      {activeStep === 3 ? <FinalStep /> : null}
    </div>
  );
};

export default DomainFormUX;
