import Button from "@mui/material/Button";
import { useLinkFormForCustomDomain } from "../../LinkForm";
import { FC } from "react";
import { DomainData } from "..";

type CreateLinkButtonProps = {
  data: DomainData
};

const CreateLinkButton: FC<CreateLinkButtonProps> = ({ data }) => {
  const proceedToCreateLink = useLinkFormForCustomDomain();
  return (
    <Button
      color="primary"
      onClick={() => proceedToCreateLink(data.subDomain.S)}
    >
      Create Link
    </Button>
  );
};

export default CreateLinkButton;
