import { useAtom } from "jotai";
import { useEffect } from "react";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { identifyUserForHotjar } from "../../../../lib/hotjar";
import { identifyUserForSentry } from "../../../../lib/sentry";
import { identifyUserForTawkTo } from "../../../../lib/tawkTo";
import { ERROR_NO_USER_IN_SESSION } from "../../data";
import { extractUserInfo, getAuthSession } from "../../libs/amplifyAuth";
import {
  loadingAtom,
  authSessionAtom,
  signInEmailAtom,
  authUserInfoAtom,
} from "../../states";

export const useSessionBL = () => {
  useSessionLoadBL();
  useSessionRefreshBL();
  useUserInfoBL();
};
const useSessionLoadBL = () => {
  const [session, setSession] = useAtom(authSessionAtom);
  const [, setLoading] = useAtom(loadingAtom);

  useEffect(() => {
    (async () => {
      if (!session) {
        setLoading(true);
        try {
          const session = await getAuthSession();
          setSession(session);
        } catch (err) {
          handleError(err as Error);
        }
        setLoading(false);
      }
    })();
  }, [session, setSession, setLoading]);
};
const useSessionRefreshBL = () => {
  const [session, setSession] = useAtom(authSessionAtom);

  useEffect(() => {
    (async () => {
      if (session) {
        const expiryTimestamp = session.getIdToken().getExpiration();
        const delayToRefresh = Math.max(expiryTimestamp * 1000 - Date.now(), 0);
        setTimeout(async () => {
          try {
            const newSession = await getAuthSession();
            setSession(newSession);
            console.log("refresh");
          } catch (err) {
            alert(ERROR_PREFIX_FOR_ALERT + err);
            window.location.reload();
          }
        }, delayToRefresh);
      }
    })();
  }, [session, setSession]);
};

const useUserInfoBL = () => {
  const [authSession] = useAtom(authSessionAtom);
  const [, setUserInfo] = useAtom(authUserInfoAtom);
  const [, setSignInEmail] = useAtom(signInEmailAtom);

  useEffect(() => {
    (async () => {
      if (authSession) {
        try {
          const userInfo = await extractUserInfo();
          const ID = userInfo?.id;
          const name = userInfo?.attributes?.name;
          const email = userInfo?.attributes?.email;
          const username = userInfo?.attributes?.sub;
          const userTier = userInfo?.attributes
            ? userInfo?.attributes["custom:UserTier"]
            : undefined;
          const numOfLinksAllowed = userInfo?.attributes
            ? userInfo?.attributes["custom:numOfLinksAllowed"]
            : undefined;
          const subsID = userInfo?.attributes
            ? userInfo?.attributes["custom:vStoryLinksSubsID"]
            : undefined;
          const subsStart = userInfo?.attributes
            ? userInfo?.attributes["custom:vStoryLinksSubsStart"]
            : undefined;
          const subsEnd = userInfo?.attributes
            ? userInfo?.attributes["custom:vStoryLinksSubsEnd"]
            : undefined;
          const disabled = userInfo?.attributes
            ? userInfo?.attributes["custom:disabled"]
            : undefined;
          setSignInEmail(email ? email : "");
          setUserInfo({
            ID,
            name,
            email,
            username,
            userTier,
            numOfLinksAllowed: parseInt(numOfLinksAllowed),
            subsID,
            subsStart: parseInt(subsStart),
            subsEnd: parseInt(subsEnd),
            disabled
          });
          identifyUserForHotjar(ID, name, email);
          identifyUserForSentry(name, email);
          identifyUserForTawkTo(name, email);
        } catch (err) {
          alert(ERROR_PREFIX_FOR_ALERT + err);
        }
      }
    })();
  }, [authSession, setUserInfo, setSignInEmail]);
};

const handleError = (err: Error) => {
  const message = (err as Error).toString();
  if (message !== ERROR_NO_USER_IN_SESSION) {
    alert(ERROR_PREFIX_FOR_ALERT + err);
  }
};
