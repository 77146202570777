import { useEffect } from "react";
import { useAtom } from "jotai";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  authSessionAtom,
  authUserAtom,
  authUserInfoAtom,
  redirectURLAtom,
} from "../states";
import { URL_APP_BILLING, URL_APP_HOME, URL_SIGNIN } from "../../../data/URLs";
import { CUSTOM_AUTH } from "../data";
import { UserInfo } from "../models";
import { getCurrentToken } from "../libs/amplifyAuth";

export const useAuthFeature = (): [
  isAuthenticated: boolean,
  userInfo: UserInfo | null,
  getAuthToken: () => Promise<string | null>
] => {
  const [userInfo] = useAtom(authUserInfoAtom);
  const [authSession] = useAtom(authSessionAtom);
  const getAuthToken = getCurrentToken;

  return [!!authSession, userInfo, getAuthToken];
};

export const useNavigateHomeIfLoggedIn = () => {
  const [URLSearchParams] = useSearchParams();
  const [, setRedirectURL] = useAtom(redirectURLAtom);
  const redirectToBilling =
    URLSearchParams && URLSearchParams.get("redirectToBilling");
  const redirectURL = redirectToBilling ? URL_APP_BILLING : URL_APP_HOME;

  useEffect(() => {
    if (redirectToBilling) {
      setRedirectURL(redirectURL);
    }
  }, [redirectToBilling, redirectURL, setRedirectURL]);

  const [isAuthenticated] = useAuthFeature();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectURL);
    }
  }, [redirectURL, isAuthenticated, navigate]);
};

export const useNavigateToSignInIfNoOTP = () => {
  const navigate = useNavigate();
  const [authUser] = useAtom(authUserAtom);

  useEffect(() => {
    const hasGeneratedOTP =
      authUser?.getAuthenticationFlowType() === CUSTOM_AUTH;
    if (!hasGeneratedOTP) {
      navigate(URL_SIGNIN);
    }
  }, [authUser, navigate]);
};
