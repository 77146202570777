import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FC, useState } from "react";
import { URL_IMG_NO_PREVIEW } from "../../../data/URLs";
import "./LinkCard.css";
import DeleteButton from "./DeleteButton";
import CopyButton from "./CopyButton";
import EditButton from "./EditButton";
import LinkCardTagChip from "./CardTagChip";
import MoreButton from "./MoreButton";
import { LinkData } from "../models";
import { makeAURL } from "../../../utils";
import { useAuthFeature } from "../../Auth";
import AnalyticsDialog from "./AnalyticsDialog";

type LinkCardProps = {
  data: LinkData;
};

const LinkCard: FC<LinkCardProps> = ({ data }) => {
  const [, userInfo] = useAuthFeature();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="link-card-wrapper">
      <LinkCardTagChip data={data} />
      <Card variant="outlined">
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia>
            <div
              className="link-card-image"
              style={{
                backgroundImage: `url("${
                  data.imageURLOG.S ? data.imageURLOG.S : URL_IMG_NO_PREVIEW
                }")`,
                filter: userInfo?.disabled ? "grayscale(100%)" : "none",
              }}
            >
              {userInfo?.disabled ? (
                <p
                  style={{
                    textAlign: "center",
                    backgroundColor: "white",
                    padding: "5px",
                    fontWeight: "700",
                  }}
                >
                  Disabled
                </p>
              ) : null}
            </div>
          </CardMedia>
          <CardContent>
            <Typography noWrap textOverflow="ellipsis">
              {data.titleOG.S}
            </Typography>
            <Typography noWrap textOverflow="ellipsis" variant="subtitle2">
              {makeAURL(data.subDomain.S, data.linkPath.S)}
            </Typography>
            <hr style={{ opacity: 0.1 }} />
            <Grid container sx={{ p: 0 }}>
              <Grid xs={6} spacing={0} justifyContent="start">
                <Typography variant="caption" textAlign="left">
                  Clicks:
                </Typography>
                <br />
                <Typography variant="h6" textAlign="left">
                  {data.viewsCount.N}
                </Typography>
              </Grid>
              <Grid xs={6} spacing={0} textAlign="right">
                <Typography variant="caption" textAlign="right">
                  Unique Visitors:
                </Typography>
                <br />
                <Typography variant="h6" textAlign="right">
                  {data.uniqueVisitorsCount.N}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <CopyButton data={data} />
          <EditButton data={data} />
          <DeleteButton data={data} />
          <MoreButton data={data} />
        </CardActions>
      </Card>
      <AnalyticsDialog data={data} showDialog={open} setShowDialog={setOpen} />
    </div>
  );
};

export default LinkCard;
