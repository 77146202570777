import { atom } from "jotai";
import { DomainStatus } from "../models";
import { DomainData } from "../../DomainsList";

export const activeStepAtom = atom<number>(0);
export const domainNameAtom = atom<string>("");
export const domainStatusAtom = atom<DomainStatus>(
  DomainStatus.WaitingToAddDNS
);
export const ipAtom = atom<string>("");
export const progressingAtom = atom<boolean>(false);
export const validation2Atom = atom<string>("");
export const validation1Atom = atom<string>("");

export const resetDomainFormActionAtom = atom(null, (_get, set) => {
  set(activeStepAtom, 0);
  set(domainNameAtom, "");
  set(progressingAtom, false);
  set(validation2Atom, "");
  set(validation1Atom, "");
  set(ipAtom, "");
});

export const prepareDomainFormActionAtom = atom(null, (_get, set, domainData: DomainData) => {  
  set(domainNameAtom, domainData.subDomain.S);
  set(domainStatusAtom, domainData.domainStatus.S);
  if (domainData.domainStatus.S === DomainStatus.WaitingToAddDNS) {
    set(activeStepAtom, 1);
  }
  if (domainData.domainStatus.S === DomainStatus.WaitingToVerifyDNS) {
    set(activeStepAtom, 2);
  }
  if (domainData.domainStatus.S === DomainStatus.WaitingToInstall) {
    set(activeStepAtom, 2);
  }
  if (domainData.domainStatus.S === DomainStatus.Working || domainData.domainStatus.S === DomainStatus.Error) {
    set(activeStepAtom, 3);
  }
  set(ipAtom, domainData.ipAddress.S);
  set(progressingAtom, false);
  set(validation2Atom, "");
  set(validation1Atom, "");
});
