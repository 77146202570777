import Typography from "@mui/material/Typography";
import {
  domainNameAtom,
  resetDomainFormActionAtom,
} from "../states";
import { useAtom } from "jotai";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { URL_APP_VIEW_ALL_DOMAINS } from "../../../data/URLs";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const ErrorStep = () => {
  const [domainName] = useAtom(domainNameAtom);
  const [, resetDomainFormAction] = useAtom(resetDomainFormActionAtom);
  const Navigate = useNavigate();

  return (
    <>
      <GppMaybeIcon sx={{ fontSize: 120 }} color="warning" />
      <Typography variant="h2" sx={{maxWidth: "500px"}} align="center">We will fix this soon!</Typography>
      <Typography variant="body1" align="center" sx={{ padding: "0 15px", maxWidth: "500px" }}>
        There was some error setting up {domainName}, but don't worry! Our engineering team is on it, and you should get an email within <strong>24 - 48 hours</strong>, once it's fixed. For now you can safely close.
      </Typography>      
      <Button
        sx={{ m: 2 }}
        color="primary"
        variant="outlined"
        onClick={() => {
          resetDomainFormAction(null);
          Navigate(URL_APP_VIEW_ALL_DOMAINS);
        }}
      >Back to Home</Button>
    </>
  ); 
};

export default ErrorStep;
