// errors
export const ERROR_NO_USER_IN_SESSION = "No current user";
export const ERROR_NOT_SIGNED_UP_YET1 = "UserLambdaValidationException: CreateAuthChallenge failed with error Cannot read properties of undefined";
export const ERROR_NOT_SIGNED_UP_YET2 = "UserNotFoundException: User does not exist.";
export const ERROR_ALREADY_SIGNED_UP = "UsernameExistsException: An account with the given email already exists.";
export const ERROR_WRONG_OTP = "No current user";
export const ALERT_WRONG_OTP = "The code you entered, was not correct. Try again";
export const ERROR_OTP_ATTEMPTS_LIMIT_REACHED = "NotAuthorizedException: Incorrect username or password.";
export const ALERT_OTP_ATTEMPTS_LIMIT_REACHED = "You entered wrong secret code 3 times! Try logging in all over again";
export const ERROR_OTP_TIME_LIMIT_REACHED = "NotAuthorizedException: Invalid session for the user.";
export const ALERT_OTP_TIME_LIMIT_REACHED = "Time limit reached! Try logging in all over again";

// data
export const CUSTOM_AUTH = "CUSTOM_AUTH";