import { useAtom } from "jotai";
import { AtomAction } from "../../../../models";
import { useAuthFeature } from "../../../Auth";
import { addDomain, isInvalidDomainName } from "../../helpers";
import {
  progressingAtom,
  domainNameAtom,
  validation1Atom,
  resetDomainFormActionAtom,
  activeStepAtom,
  ipAtom,
} from "../../states";
import { ERROR_CANT_CREATE_MORE_DOMAIN, ERROR_CHECK_INTERNET, ERROR_CONDITIONAL_CHECK_FAILED, ERROR_DOMAIN_ENGAGED_OTHERWISE, ERROR_EMPTY_DOMAIN, ERROR_INVALID_DOMAIN } from "../../data";
import { DomainData, useDomainsData } from "../../../DomainsList";

export const useStep1BL = (): [
  validation: string,
  progressing: boolean,
  submitStep1: Function,
  resetDomainFormAction: AtomAction<null>
] => {
  const [,,getAuthToken] = useAuthFeature();
  const [domainName] = useAtom(domainNameAtom);
  const [, setIp] = useAtom(ipAtom);
  const [progressing, setProgressing] = useAtom(progressingAtom);
  const [validation, setValidation] = useAtom(validation1Atom);
  const [, setActiveStep] = useAtom(activeStepAtom);
  const [, resetDomainFormAction] = useAtom(resetDomainFormActionAtom);
  const [addToDomainsDataList] = useDomainsData();

  const submitStep1 = async () => {
    if (domainName === "") {
      setValidation(ERROR_EMPTY_DOMAIN);
      return;
    }

    if (isInvalidDomainName(domainName)) {
      setValidation(ERROR_INVALID_DOMAIN);
      return;
    }
    
    setProgressing(true);
    try {
      const accessToken = await getAuthToken();
      const response = await addDomain(accessToken, domainName);

      if (response?.error?.name === ERROR_CONDITIONAL_CHECK_FAILED){
        setValidation(ERROR_DOMAIN_ENGAGED_OTHERWISE);
        setProgressing(false);
        return;
      }

      if (response?.error === ERROR_CANT_CREATE_MORE_DOMAIN) {
        setValidation(ERROR_CANT_CREATE_MORE_DOMAIN);
        setProgressing(false);
        return;
      }

      if (response?.ip) {
        const {ip} = response;
        setIp(ip);  
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (response?.dbRes?.Item) {
          const domainData = response.dbRes.Item as DomainData;
          addToDomainsDataList(domainData);
        }
      }
    } catch(e) {
      console.error(e);
      setValidation(ERROR_CHECK_INTERNET);
    }
    setProgressing(false);
  };

  return [
    validation,
    progressing,
    submitStep1,
    resetDomainFormAction,
  ];
};
