export const URL_SIGNUP = "/app/auth/signup";
export const URL_SIGNIN = "/app/auth/login";
export const URL_VERIFY_OTP = "/app/auth/verify-otp";
export const URL_APP_HOME = "/app";
export const URL_APP_VIEW_ALL_LINKS = "/app/links/all";
export const URL_APP_CREATE_NEW_LINK = "/app/link/create";
export const URL_APP_UPDATE_NEW_LINK = "/app/link/create";
export const URL_APP_ADD_DOMAIN = "/app/domain/add";
export const URL_APP_VIEW_ALL_DOMAINS = "/app/domains/all";
export const URL_APP_BILLING = "/app/billing";
export const URL_APP_BILLING_SUCCESS = "/app/billing/success";
export const URL_IMG_NO_PREVIEW = "/app/no-preview.svg";
export const URL_RAZORPAY_CHECKOUT_SDK = "https://checkout.razorpay.com/v1/checkout.js";
export const URL_TERMS_OF_SERVICE = "/policies/terms-of-service/";
export const URL_PRIVACY_POLICY = "/policies/privacy/";