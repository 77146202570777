import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useAuthFeature } from "../../Auth";
import { listDomains } from "../helpers";
import { DomainData, DomainStatus, DomainsDataResponse } from "../models";
import {
  domainsDataLoadingAtom,
  domainsDataLoadedAtom,
  domainsDataResponseAtom,
  removeFromDomainsDataListAtom,
  addToDomainsDataListAtom,
  updateDomainsDataListAtom,
} from "../states";

export const useDomainsData = (): [
  addToDomainsDataList: (domainData: DomainData) => void,
  updateDomainsDataList: (domainData: DomainData) => void,
  removeFromDomainsDataList: (domainName: string) => void
] => {
  const [, addToDomainsDataList] = useAtom(addToDomainsDataListAtom);
  const [, updateDomainsDataList] = useAtom(updateDomainsDataListAtom);
  const [, removeFromDomainsDataList] = useAtom(removeFromDomainsDataListAtom);
  return [
    addToDomainsDataList,
    updateDomainsDataList,
    removeFromDomainsDataList,
  ];
};

export const useLoadDomainsDataFn = (): [loadDomainsData: Function] => {
  const [,,getAuthToken] = useAuthFeature();
  const [, setLoading] = useAtom(domainsDataLoadingAtom);
  const [, setLoaded] = useAtom(domainsDataLoadedAtom);
  const [, setDomainsDataResponse] = useAtom(domainsDataResponseAtom);

  const loadDomainsData = useCallback(async () => {
    setLoaded(false);
    setLoading(true);
    const accessToken = await getAuthToken();
    const data = await listDomains(accessToken);
    setDomainsDataResponse({
      list: data.Items,
    });
    setLoaded(true);
    setLoading(false);
  }, [getAuthToken, setLoading, setLoaded, setDomainsDataResponse]);

  return [loadDomainsData];
};

export const useLoadDomainsListBL = (): [
  domainsDataResponse: DomainsDataResponse,
  loading: boolean,
  loaded: boolean
] => {
  const [accessToken] = useAuthFeature();
  const [loading] = useAtom(domainsDataLoadingAtom);
  const [loaded, setLoaded] = useAtom(domainsDataLoadedAtom);
  const [domainsDataResponse] = useAtom(domainsDataResponseAtom);
  const [loadDomainsData] = useLoadDomainsDataFn();

  useEffect(() => {
    setLoaded(false);
  }, [setLoaded]);

  useEffect(() => {
    (async () => {
      if (!loading && !loaded && accessToken) {
        loadDomainsData();
      }
    })();
  }, [accessToken, loading, loaded, loadDomainsData]);

  return [domainsDataResponse, loading, loaded];
};

export const useLoadDomainNamesListBL = (): [domainNamesList: string[]] => {
  const [domainsDataResponse] = useLoadDomainsListBL();
  const domainNamesList = domainsDataResponse.list.map((d) => {
    if (d.domainStatus.S === DomainStatus.Working) {
      return d.subDomain.S;
    }

    return "";
  }).filter((d) => !!d);

  return [domainNamesList];
};

let interval: NodeJS.Timer | undefined;
export const useDomainRefresh = () => {
  const [domainsDataResponse, setDomainsDataResponse] = useAtom(domainsDataResponseAtom);
  const [,,getAuthToken] = useAuthFeature();

  useEffect(() => {
    const domainsToRefresh = domainsDataResponse.list.filter(
      (d) =>
        d.domainStatus.S === DomainStatus.WaitingToVerifyDNS ||
        d.domainStatus.S === DomainStatus.WaitingToInstall
    );
    if (domainsToRefresh && domainsToRefresh.length) {
      interval = setInterval(async () => {
        const accessToken = await getAuthToken();
        const data = await listDomains(accessToken);
        setDomainsDataResponse({
          list: data.Items,
        });
      }, 5000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainsDataResponse]);
};
