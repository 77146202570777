declare global {
  var lintrk: Function;
}

export const reportSignupToLinkedin = () => {
  window.lintrk('track', { conversion_id: 14326865 });
};

export const reportPurchaseToLinkedin = () => {
  window.lintrk('track', { conversion_id: 14326873 });
};