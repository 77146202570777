declare global {
  var twq: Function;
}

export const reportSignupToTwitter = (email: string) => {
  twq('event', 'tw-ofiup-ofivd', {
    email_address: email
  });
};

export const reportPurchaseToTwitter = () => {  
  twq('event', 'tw-ofiup-ofivf', {
    value: 20
  });
};