import { Amplify } from "@aws-amplify/core";
import { authConfig } from "../features/Auth";

Amplify.configure({
  Auth: authConfig,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_OPENGRAGH_LINKS_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
      level: "protected",
      customPrefix: {
        protected: "assets/",
      },
    },
  },
});
