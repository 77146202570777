import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const PaymentLoading = () => {
  return (
    <div className="billing-container">
    <CircularProgress />
    <br />
    <Typography variant="h2" component="h2">
      Loading
    </Typography>
  </div>
  );
}

export default PaymentLoading;