import { getRandomString } from "../../../utils";
import { Auth } from "@aws-amplify/auth";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

export const getAuthSession = (): Promise<CognitoUserSession> =>
  Auth.currentSession();

export const signOut = () => Auth.signOut();

export const signIn = (email: string) => Auth.signIn(email);

export const signUp = (name: string, email: string) => {
  const password = getRandomString(30);
  return Auth.signUp({
    username: email,
    password,
    attributes: { name },
  });
};

export const verifyOTP = (user: CognitoUser, otp: string) => {
  return Auth.sendCustomChallengeAnswer(user, otp);
};

export const extractAccessTokenFromSession = (
  session: CognitoUserSession
): string | null => {
  if (!session) {
    return null;
  }

  return session.getIdToken().getJwtToken();
};

export const getCurrentToken = async (): Promise<string | null> => {  
  try {
    const session = await Auth.currentSession();
    return extractAccessTokenFromSession(session);  
  } catch(e) {
    return null;
  }
};

export const extractUserInfo = () => {
  return Auth.currentUserInfo();
};