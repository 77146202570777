import { useAtom } from "jotai";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { isValidHttpDomain, isValidHttpUrl, makeAURL } from "../../../../utils";
import { useAuthFeature } from "../../../Auth";
import { getLinkAvailability, hasBlacklistedDomain, isARedirectLoop } from "../../helpers";
import {
  activeStepAtom,
  lastLinkPathAtom,
  lastSubDomainAtom,
  linkPathAtom,
  progressingAtom,
  resetLinkFormActionAtom,
  validation1Atom,
  subDomainAtom,
  redirectURLAtom,
} from "../../states";

export const useStep2BL = (): [
  subDomain: string,
  linkPath: string,
  progressing: boolean,
  validation: string,
  submitStep2: Function,
  resetLinkFormAction: Function
] => {
  const [,,getAuthToken] = useAuthFeature();
  const [subDomain] = useAtom(subDomainAtom);
  const [linkPath] = useAtom(linkPathAtom);
  const [redirectURL] = useAtom(redirectURLAtom);
  const [lastSubDomain, setLastSubDomain] = useAtom(lastSubDomainAtom);
  const [lastLinkPath, setLastLinkPath] = useAtom(lastLinkPathAtom);
  const [, setActiveStep] = useAtom(activeStepAtom);
  const [progressing, setProgressing] = useAtom(progressingAtom);
  const [validation, setValidation] = useAtom(validation1Atom);
  const [, resetLinkFormAction] = useAtom(resetLinkFormActionAtom);

  const submitStep2 = async () => {
    if (validation) {
      return;
    }

    const noSubdomain = subDomain === "";
    const noLinkPath = linkPath === "";
    const isValidURL =
      isValidHttpDomain(makeAURL(subDomain, "")) &&
      isValidHttpUrl(makeAURL(subDomain, linkPath));
    if (noSubdomain) {
      setValidation("Subdomain can't be empty. It should be <Your-Brand>.vstory.co");
      return;
    }

    if (noLinkPath) {
      setValidation("link path can't be empty. It should be <Your-Brand>.vstory.co/<Some-Meaningful-Path>");
      return;
    }

    if (!isValidURL) {
      setValidation(`${subDomain}.vstory.co/${linkPath} is not a valid link`);
      return;
    }

    if (hasBlacklistedDomain(subDomain)) {
      setValidation(`${subDomain}.vstory.co is not allowed`);
      return;
    }

    if (isARedirectLoop(subDomain, linkPath, redirectURL)) {
      setValidation("your link can't be same as your Redirect Destination");
      return;
    }

    if (lastSubDomain === subDomain && lastLinkPath === linkPath) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    setProgressing(true);
    try {
      const accessToken = await getAuthToken();
      const isLinkAvailable = await getLinkAvailability(
        accessToken,
        subDomain,
        linkPath
      );
      setLastSubDomain(subDomain);
      setLastLinkPath(linkPath);
      if (isLinkAvailable) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setValidation("someone else is already using this exact link");
      }
    } catch (err) {
      alert(ERROR_PREFIX_FOR_ALERT + err);
    }
    setProgressing(false);
  };

  return [subDomain, linkPath, progressing, validation, submitStep2, resetLinkFormAction];
};
