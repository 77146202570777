import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import { URL_SIGNIN } from "../../../data/URLs";
import SubmitButton from "../../../components/SubmitButton";
import { useNavigateHomeIfLoggedIn } from "../hooks";
import { useSignUpBL } from "../hooks/BL/signUp";
import NoticeForEULA from "../components/NoticeForEULA";

const AuthSignUp = () => {
  useNavigateHomeIfLoggedIn();
  const [
    name,
    email,
    signUpValidation,
    signingUp,
    signUpFn,
    onNameChange,
    onEmailChange,
  ] = useSignUpBL();

  return (
    <form
      className="is-full-size is-centered"
      onSubmit={(e) => {
        e.preventDefault();
        signUpFn();
      }}
    >
      <Typography variant="h2" component="h1">
        Sign Up
      </Typography>
      <br />
      <TextField
        placeholder="Your Name"
        variant="standard"
        className="is-centered-item"
        inputProps={{ className: "data-hj-allow" }}
        onChange={(e) => onNameChange(e.target.value)}
        autoFocus={true}
        value={name}
      />
      <br />
      <TextField
        placeholder="Your Email"
        variant="standard"
        className="is-centered-item"
        inputProps={{ className: "data-hj-allow" }}
        type="email"
        onChange={(e) => onEmailChange(e.target.value)}
        value={email}
      />
      {signUpValidation ? <br /> : null}
      {signUpValidation ? (
        <Alert
          severity="warning"
          variant="outlined"
          className="is-centered-item"
        >
          The email is not valid
        </Alert>
      ) : null}
      <br />
      <SubmitButton
        showProgress={signingUp}
        progressText="Verifying your email"
        defaultText="Next"
      />
      <hr />
      <Typography variant="caption">
        Already have an account?{" "}
        <Link href={URL_SIGNIN} color="inherit">
          Log in
        </Link>
      </Typography>
      <NoticeForEULA />
    </form>
  );
};

export default AuthSignUp;
