import { useAtom } from "jotai";
import { URL_IMG_NO_PREVIEW } from "../../../data/URLs";
import {
  descriptionOGAtom,
  imageURLOGAtom,
  titleOGAtom,
  subDomainAtom,
} from "../states";
import "./LinkPreview.css";
import { makeAURL } from "../../../utils";

const LinkPreview = () => {
  const [titleOG] = useAtom(titleOGAtom);
  const [descriptionOG] = useAtom(descriptionOGAtom);
  const [imageURLOG] = useAtom(imageURLOGAtom);
  const [subDomain] = useAtom(subDomainAtom);

  const imageURL = imageURLOG ? imageURLOG : URL_IMG_NO_PREVIEW;

  return (
    <>
      <div
        className="link-preview-image"
        style={{
          backgroundImage: 'URL("' + imageURL + '")',
          backgroundSize: "cover",
        }}
      ></div>
      <div className="link-preview-body">
        <div className="link-preview-font-domain">
          {`${makeAURL(subDomain, "")}`.split("https://").join("").toUpperCase()}
        </div>
        <div className="link-preview-font-title">
          <strong>{titleOG ? titleOG : "No Title :("}</strong>
        </div>
        <div className="link-preview-font-description">
          {descriptionOG ? descriptionOG : "No Description :( :("}
        </div>
      </div>
    </>
  );
};

export default LinkPreview;
