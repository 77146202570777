import Typography from "@mui/material/Typography";
import { useStep3BL } from "../hooks/BL/step3";
import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import { URL_APP_VIEW_ALL_DOMAINS } from "../../../data/URLs";
import WaitingToVerify from "../components/WaitingToVerify";
import WaitingToInstall from "../components/WaitingToInstall";

const Step3 = () => {
  const [domainName, domainStatus, resetDomainFormAction] = useStep3BL();
  const Navigate = useNavigate();

  return (
    <form
      className="is-centered"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Typography variant="h6">Verify & Integrate</Typography>
      {!domainStatus || domainStatus === "WaitingToVerifyDNS" ? (
        <WaitingToVerify domainName={domainName} />
      ) : (
        <WaitingToInstall domainName={domainName} />
      )}
      <br />
      <BackButton
        text="Back to Home"
        onClick={() => {
          resetDomainFormAction(null);
          Navigate(URL_APP_VIEW_ALL_DOMAINS);
        }}
      />
    </form>
  );
};

export default Step3;
