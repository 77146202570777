export const blacklistedDomains = [  
  "links",
  "link",  
  "opengraph-links",
  "blogs",
  "forms",
  "pages",
  "landing-pages",
  "albums",
  "portfolio",
  "bio",
  "wiki",
  "career",  
  "sites",
  "mail",
  "converse",
  "reels",
  "campaign",
  "campaigns",
  "developers",
  "api",
  "test",
  "testing",
  "dev",
  "prod",
  "watchvideo",
  "watch",
  "quickloansettle",
  "www",
  "tech",
  "facebook-login-pokemon"
];
