import { BRAND_COLOR } from "../../../data/theme";
import { reportPurchaseToGoogleAds } from "../../../lib/gtag";
import { reportPurchaseToLinkedin } from "../../../lib/linkedin";

declare global {
  var Razorpay: any;
}
let rzp: any;

export const initRazorpay = (
  userID: string,
  email: string,
  subscription_id: string,
  cb: Function
) => {
  var options = {
    key:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_RAZORPAY_KEY_PROD
        : process.env.REACT_APP_RAZORPAY_KEY_TEST,
    amount: "382800",
    currency: "INR",
    name: "vStory",
    description: "subcription for vStory Links Pro",
    subscription_id,
    recurring: true,
    remember_customer: true,
    handler: (response: any) => {
      if (
        response.razorpay_payment_id &&
        response.razorpay_subscription_id &&
        response.razorpay_signature
      ) {
        reportPurchaseToGoogleAds(response.razorpay_subscription_id);
        reportPurchaseToLinkedin();
        cb();
      }
    },
    prefill: { email },
    theme: {
      color: BRAND_COLOR,
    },
    notes: { userID },
  };
  rzp = new Razorpay(options);
};

export const openRazorpay = () => {
  rzp.open();
};

export const createSubscription = async (accessToken: string | null) => {
  const url = new URL(
    process.env.REACT_APP_API_GATEWAY_BASEURL + "/razorpay/subscription/create"
  );
  const planID =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_RAZORPAY_PLANID_PROD
      : process.env.REACT_APP_RAZORPAY_PLANID_TEST;
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ planID }),
  });
  const data = await response.json();
  return data.response;
};