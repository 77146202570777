// Menu Highlight
export const getMenuHighlightValue = () => {
  const value = localStorage.getItem("vStory-link-Navigation-Menu-Highlight");
  const hightlightInit = value ? value === "true" : true;
  return hightlightInit;
};

export const storeMenuHighlightValue = () => {
  localStorage.setItem("vStory-link-Navigation-Menu-Highlight", "false");
};

// Domain Uninstalled
export const storeDomainUninstalled = (domain: string) => {
  localStorage.setItem("vStory-link-domain-uninstalled-"+domain.toLowerCase(), new Date().toISOString());
};

export const removeDomainUninstalled = (domain: string) => {
  localStorage.removeItem("vStory-link-domain-uninstalled-"+domain.toLowerCase());
};

export const getDomainUninstalled = (domain: string) => {
  return localStorage.getItem("vStory-link-domain-uninstalled-"+domain.toLowerCase());
};
