export enum LinkFormMode {
  CreateWithvStoryCo = "create-with-vstory-co",
  CreateWithCustomDomain = "create-with-custom-domain",
  Update = "update",
}

export enum URLType {
  WebLink = "web-link",
  Email = "mailto-link",
  Phone = "tel-link",
}
