import { useAtom } from "jotai";
import { paymentDoneAtom } from "../states";
import PaymentProcess from "./PaymentProcess";
import PaymentSuccess from "./PaymentSuccess";

const BillingForUnpaidUser = () => {
  const [paymentDone] = useAtom(paymentDoneAtom);

  if (paymentDone) {
    return <PaymentSuccess />;
  }

  return <PaymentProcess />;
};

export default BillingForUnpaidUser;
