import { useEffect, useState } from "react";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { LocalStateAction } from "../../../../models";
import { useAuthFeature } from "../../../Auth";
import { deleteLink, deleteLinkTag, updateLinkTag } from "../../helpers";
import { LinkData } from "../../models";
import { useLinkData } from "..";

export const useDeleteLinkBL = (
  data: LinkData
): [deleting: boolean, deleteLinkFn: Function] => {
  const [,,getAuthToken] = useAuthFeature();
  const [deleting, setDeleting] = useState(false);
  const [, , deleteFromLinksDataList] = useLinkData();

  const deleteLinkFn = async () => {
    setDeleting(true);
    try {
      const accessToken = await getAuthToken();
      await deleteLink(accessToken, data.fullPath.S);
      deleteFromLinksDataList(data.fullPath.S);
    } catch (e) {
      alert(ERROR_PREFIX_FOR_ALERT + e);
    }
    setDeleting(false);
  };

  return [deleting, deleteLinkFn];
};

export const useUpdateTagBL = (
  data: LinkData,
  setShowDialog: LocalStateAction<boolean>
): [
  tag: string,
  saving: boolean,
  setTag: LocalStateAction<string>,
  onDialogClose: Function,
  onDialogSubmit: Function
] => {
  const [saving, setSaving] = useState<boolean>(false);
  const [,,getAuthToken] = useAuthFeature();
  const [, , , updateTagInLinksDataList] = useLinkData();
  const initTag = data.tag && data.tag.S ? data.tag.S : "";
  const [tag, setTag] = useState<string>(initTag);

  const onDialogClose = () => {
    if (saving) {
      return;
    }
    setShowDialog(false);
  };

  const onDialogSubmit = async () => {
    setSaving(true);
    try {
      const accessToken = await getAuthToken();
      await updateLinkTag(accessToken, data.fullPath.S, tag);
    } catch (e) {
      alert("Error updating tag on the link" + e);
    }
    updateTagInLinksDataList(data.fullPath.S, tag);
    setSaving(false);
    setShowDialog(false);
  };

  return [tag, saving, setTag, onDialogClose, onDialogSubmit];
};

export const useDeleteTagBL = (
  data: LinkData,
  setShowDialog: LocalStateAction<boolean>
): [deleting: boolean, onDialogClose: Function, onDialogSubmit: Function] => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [,,getAuthToken] = useAuthFeature();
  const [, , , , deleteTagFromLinksDataList] = useLinkData();

  const onDialogClose = () => {
    if (deleting) {
      return;
    }
    setShowDialog(false);
  };

  const onDialogSubmit = async () => {
    setDeleting(true);
    try {
      const accessToken = await getAuthToken();
      await deleteLinkTag(accessToken, data.fullPath.S);
    } catch (e) {
      alert("Error deleting tag on the link" + e);
    }
    deleteTagFromLinksDataList(data.fullPath.S);
    setDeleting(false);
    setShowDialog(false);
  };

  return [deleting, onDialogClose, onDialogSubmit];
};

const useQRCodeModule = (showDialog: boolean) => {
  const [QRModule, setQRModule] = useState<any>();
  useEffect(() => {
    if (showDialog) {
      import("../../../QRCode").then((lib) => {
        setQRModule(lib);
      });
    }
  }, [showDialog]);

  const QRCodeAndControls = QRModule?.default;
  const downloadQRCode = QRModule?.downloadQRCode;
  return [QRCodeAndControls, downloadQRCode]
};

export const useDownloadQRCodeBL = (
  data: LinkData,
  showDialog: boolean,
  setShowDialog: LocalStateAction<boolean>
): [downloading: boolean, QRCodeAndControls: any, onDialogClose: Function, onDialogSubmit: Function] => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const [QRCodeAndControls, downloadQRCode] = useQRCodeModule(showDialog);

  const onDialogClose = () => {
    if (downloading) {
      return;
    }
    setShowDialog(false);
  };

  const onDialogSubmit = () => {
    const imageName = `QRCode-${data.subDomain.S}-${data.linkPath.S}.png`;
    setDownloading(true);
    if (downloadQRCode) {
      downloadQRCode(imageName);
    }
    setDownloading(false);
  };

  return [downloading, QRCodeAndControls, onDialogClose, onDialogSubmit];
};

export const useLinkAnalyticsModule = (showDialog: boolean) => {
  const [LinkAnalyticsModule, setLinkAnalyticsModule] = useState<any>();
  useEffect(() => {
    if (showDialog) {
      import("../../../LinkAnalytics").then((lib) => {
        setLinkAnalyticsModule(lib);
      });
    }
  }, [showDialog]);
  
  const LinkAnalyticsReport = LinkAnalyticsModule?.default;
  const LinkAnalyticsRefreshButton = LinkAnalyticsModule?.LinkAnalyticsRefreshButton
  return [LinkAnalyticsReport, LinkAnalyticsRefreshButton];
};