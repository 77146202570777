import SvgIcon from "@mui/material/SvgIcon";
import { FC } from "react";

type MenuIconProps = {
  highlight: boolean
};

const MenuIcon: FC<MenuIconProps> = ({highlight}) => {
  if (highlight) {
    return (
      <SvgIcon viewBox="0 0 16.3 16.3" color="inherit">
        <path d="M13.08,6.54v4.52h1.47l-1.15,1.27-1.15,1.27-1.15-1.27-1.15-1.27h1.47V2.02h1.65s0,4.52,0,4.52Z" />
        <path d="M10.24,2.92v.9H5.12S.01,3.8,.01,3.8v-.89s-.01-.89-.01-.89H10.25v.9h0Z" />
        <path d="M10.21,6.12v.89s-5.1,.01-5.1,.01H0s0-.89,0-.89v-.9H10.21v.89Z" />
        <path d="M10.19,8.63s.02,.42,.02,.9v.87H0v-.89s.01-.89,.01-.89H5.09c2.79,0,5.09,0,5.1,.01Z" />
        <path d="M9.31,11.84s.02,.42,.02,.9v.87H0v-1.79H4.64c2.56,0,4.66,.01,4.67,.02Z" />
        <circle cx="14.28" cy="2.02" r="2.02" fill="#ed1c24" />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon viewBox="0 0 14.55 11.59" color="inherit">
      <path d="M13.08,4.52v4.52h1.47l-1.15,1.27-1.15,1.27-1.15-1.27-1.15-1.27h1.47V0h1.65s0,4.52,0,4.52Z" />
      <path d="M10.24,.9v.9H5.12S.01,1.78,.01,1.78V.89S0,0,0,0H10.25V.9h0Z" />
      <path d="M10.21,4.1v.89s-5.1,.01-5.1,.01H0s0-.89,0-.89v-.9H10.21v.89Z" />
      <path d="M10.19,6.61s.02,.42,.02,.9v.87H0v-.89s.01-.89,.01-.89H5.09c2.79,0,5.09,0,5.1,.01Z" />
      <path d="M9.31,9.82s.02,.42,.02,.9v.87H0v-1.79H4.64c2.56,0,4.66,.01,4.67,.02Z" />
    </SvgIcon>
  );
};

export default MenuIcon;