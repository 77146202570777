import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import { URL_SIGNUP } from "../../../data/URLs";
import SubmitButton from "../../../components/SubmitButton";
import { useSignInBL } from "../hooks/BL/signIn";
import { useNavigateHomeIfLoggedIn } from "../hooks";
import NoticeForEULA from "../components/NoticeForEULA";

const AuthSignIn = () => {
  useNavigateHomeIfLoggedIn();
  const [email, signInValidation, creatingSignInOTP, signInFn, onEmailChange] =
    useSignInBL();

  return (
    <form
      className="is-full-size is-centered"
      onSubmit={(e) => {
        e.preventDefault();
        signInFn();
      }}
    >
      <Typography variant="h2" component="h1">
        Log In
      </Typography>
      <br />
      <TextField
        placeholder="Your Email"
        variant="standard"
        className="is-centered-item"
        type="email"
        inputProps={{ className: "data-hj-allow" }}
        onChange={(e) => onEmailChange(e.target.value)}
        autoFocus={true}
        value={email}
      />
      {signInValidation ? <br /> : null}
      {signInValidation ? (
        <Alert
          severity="warning"
          variant="outlined"
          className="is-centered-item"
        >
          The email is not valid
        </Alert>
      ) : null}
      <br />
      <SubmitButton
        showProgress={creatingSignInOTP}
        progressText="Verifying your email"
        defaultText="Next"
      />
      <hr />
      <Typography variant="caption">
        Don't have an account?{" "}
        <Link href={URL_SIGNUP} color="inherit">
          Sign up
        </Link>
      </Typography>
      <NoticeForEULA />
    </form>
  );
};

export default AuthSignIn;
