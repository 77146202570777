import { useAtom } from "jotai";
import { useAuthFeature } from "../../../Auth";
import {
  activeStepAtom,
  progressingAtom,
  resetDomainFormActionAtom,
  domainNameAtom,
  domainStatusAtom,
} from "../../states";
import { checkDomainDNS } from "../../helpers";
import { DomainStatus } from "../../models";
import { DomainData, useDomainsData } from "../../../DomainsList";

export const useStep2BL = (): [
  domainName: string,
  progressing: boolean,
  submitStep2: Function,
  resetDomainFormAction: Function
] => {
  const [,,getAuthToken] = useAuthFeature();
  const [domainName] = useAtom(domainNameAtom);
  const [, setDomainStatus] = useAtom(domainStatusAtom);
  const [, setActiveStep] = useAtom(activeStepAtom);
  const [progressing, setProgressing] = useAtom(progressingAtom);
  const [, resetDomainFormAction] = useAtom(resetDomainFormActionAtom);
  const [, updateDomainsDataList] = useDomainsData();

  const submitStep2 = async () => {
    setProgressing(true);
    try {
      const accessToken = await getAuthToken();
      const response = await checkDomainDNS(accessToken, domainName);
      if (typeof response?.match === "undefined") {
        setProgressing(false);
        return;
      }

      if (response?.match === true) {
        setDomainStatus(DomainStatus.WaitingToInstall);
      }

      if (response?.match === false) {
        setDomainStatus(DomainStatus.WaitingToVerifyDNS);
      }

      if (response?.dbRes?.Attributes?.domainName) {
        const domainData = response.dbRes.Attributes as DomainData;
        updateDomainsDataList(domainData);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch(e) {
      console.error(e);
    }
    setProgressing(false);
  };

  return [domainName, progressing, submitStep2, resetDomainFormAction];
};
