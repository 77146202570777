import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";
import { DomainStatus } from "../models";

const DomainStatusProgress: FC<{ status: DomainStatus }> = ({ status }) => {
  if (
    status !== DomainStatus.WaitingToInstall &&
    status !== DomainStatus.WaitingToVerifyDNS
  ) {
    return null;
  }

  return (
    <>
      &nbsp;&nbsp;
      <CircularProgress size={14} color="warning" />
    </>
  );
};

export default DomainStatusProgress;
