import { atom } from "jotai";
import { DomainData, DomainsDataResponse } from "../models";

export const loadingAtom = atom<boolean>(false);
export const loadedAtom = atom<boolean>(false);
export const domainsDataResponseAtom = atom<DomainsDataResponse>({
  list: []
});
export const domainsDataLoadingAtom = atom<boolean>(false);
export const domainsDataLoadedAtom = atom<boolean>(false);
export const addToDomainsDataListAtom = atom(
  null,
  (get, set, domainData: DomainData) => {
    const domainsDataResponse = get(domainsDataResponseAtom);
    domainsDataResponse.list = [domainData, ...domainsDataResponse.list];
    set(domainsDataResponseAtom, {...domainsDataResponse});
  }
);

export const updateDomainsDataListAtom = atom(
  null,
  (get, set, domainData: DomainData) => {
    const domainsDataResponse = get(domainsDataResponseAtom);
    domainsDataResponse.list = domainsDataResponse.list.map((d)=>{
      if (d.domainName.S === domainData.domainName.S) {
        return domainData;
      }

      return d;
    });
    set(domainsDataResponseAtom, {...domainsDataResponse});
  }
);

export const removeFromDomainsDataListAtom = atom(
  null,
  (get, set, domainName: string) => {
    const domainsDataResponse = get(domainsDataResponseAtom);
    domainsDataResponse.list = domainsDataResponse.list.filter((d)=>{
      return d.domainName.S !== domainName;
    });
    set(domainsDataResponseAtom, {...domainsDataResponse});
  }
);
