import { CognitoUser } from "amazon-cognito-identity-js";
import { useAtom } from "jotai";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ERROR_PREFIX_FOR_ALERT } from "../../../../data";
import { URL_SIGNIN } from "../../../../data/URLs";
import { validateOTP } from "../../../../utils";
import {
  ALERT_OTP_ATTEMPTS_LIMIT_REACHED,
  ALERT_OTP_TIME_LIMIT_REACHED,
  ALERT_WRONG_OTP,
  ERROR_OTP_ATTEMPTS_LIMIT_REACHED,
  ERROR_OTP_TIME_LIMIT_REACHED,
  ERROR_WRONG_OTP,
} from "../../data";
import { getAuthSession, verifyOTP } from "../../libs/amplifyAuth";
import {
  OTPAtom,
  progressingAtom,
  authSessionAtom,
  authUserAtom,
  redirectURLAtom,
  signInEmailAtom,
} from "../../states";
import { checkAndReportSignupToAnalytics } from "../../helpers";

export const useOTPVerificationBL = (): [
  OTP: string,
  OTPValidation: boolean,
  OTPVerifying: boolean,
  verifyOTPFn: Function,
  onOTPChange: Function,
  onOTPExpire: Function
] => {
  const [signInEmail] = useAtom(signInEmailAtom);
  const [OTP, setOTP] = useAtom(OTPAtom);
  const [, setSession] = useAtom(authSessionAtom);
  const [authUser] = useAtom(authUserAtom);
  const navigate = useNavigate();
  const [OTPValidation, setOTPValidation] = useState(false);
  const [OTPVerifying, setOTPVerifying] = useAtom(progressingAtom);
  const [redirectURL] = useAtom(redirectURLAtom);

  const verifyOTPFn = async () => {
    if (!authUser) {
      return;
    }

    if (!validateOTP(OTP)) {
      setOTPValidation(true);
      return;
    }

    setOTPVerifying(true);
    try {
      const newAuthUser: CognitoUser = await verifyOTP(authUser, OTP);
      if (newAuthUser) {
        checkAndReportSignupToAnalytics(signInEmail);
        const newSession = await getAuthSession();        
        setSession(newSession);
        navigate(redirectURL);
      }
    } catch (err) {
      handleError(err as Error, navigate);
    }
    setOTPVerifying(false);
  };

  const onOTPChange = (OTP: string) => {
    if (validateOTP(OTP)) {
      setOTPValidation(false);
    }

    setOTP(OTP);
  };

  const onOTPExpire = () => {
    navigate(URL_SIGNIN);
  };

  return [
    OTP,
    OTPValidation,
    OTPVerifying,
    verifyOTPFn,
    onOTPChange,
    onOTPExpire,
  ];
};

const handleError = (error: Error, navigate: NavigateFunction) => {
  const message = error.toString();
  if (message.includes(ERROR_WRONG_OTP)) {
    alert(ALERT_WRONG_OTP);
  } else if (message === ERROR_OTP_ATTEMPTS_LIMIT_REACHED) {
    alert(ALERT_OTP_ATTEMPTS_LIMIT_REACHED);
    navigate(URL_SIGNIN);
  } else if (message === ERROR_OTP_TIME_LIMIT_REACHED) {
    alert(ALERT_OTP_TIME_LIMIT_REACHED);
    navigate(URL_SIGNIN);
  } else {
    alert(ERROR_PREFIX_FOR_ALERT + error);
  }
};
