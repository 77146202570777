declare global {
  var Tawk_API: any;
}

export const identifyUserForTawkTo = (
  name: string,
  email: string
) => {
  if (process.env.NODE_ENV === "production" && Tawk_API) {
    try {
      Tawk_API.setAttributes({ name, email }, function (err: any) {console.error(err)});
    } catch (e) {
      console.error(e);
    }
  }
};