import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import "./ImageCropper.css";
import { useAtom } from "jotai";
import { imgURLToCropAtom } from "../states";
import Link from "@mui/material/Link";
import ImageUploader from "./ImageUploader";

const ImageCropper = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imgURLToCrop, setImgURLToCrop] = useAtom(imgURLToCropAtom);

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <div className="og-image-crop-container">
      <div className="og-image-crop">
        <Cropper
          image={imgURLToCrop}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1200 / 627}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="og-image-crop-controls">
        <div className="og-image-zoom-slider">
          <Typography variant="overline" color="white">
            Zoom
          </Typography>
          <Slider
            value={Math.round(zoom * 10) / 10}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(_, zoom) => setZoom(zoom as number)}
          />
        </div>
        <div className="og-image-rotation-slider">
          <Typography variant="overline" color="white">
            Rotation
          </Typography>
          <Slider
            value={Math.round(rotation)}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rotation) => setRotation(rotation as number)}
          />
        </div>
        <ImageUploader
          rotation={rotation}
          croppedAreaPixels={croppedAreaPixels}
        />
        <Link
          color="white"
          underline="hover"
          textAlign="center"
          className="og-image-crop-cancel has-pointer-cursor"
          onClick={() => setImgURLToCrop("")}
        >
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default ImageCropper;
