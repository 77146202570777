import { useAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { is301Atom, redirectURLTypeAtom } from "../states";
import Checkbox from "@mui/material/Checkbox";
import "./Is301Checkbox.css";
import { URLType } from "../models";

type Is301CheckboxProps = {
  className?: string;
};

const Is301Checkbox: FC<Is301CheckboxProps> = (props) => {
  const [canDo301, setCanDo301] = useState<boolean>(true);
  const [is301, setIs301] = useAtom(is301Atom);
  const [redirectURLType] = useAtom(redirectURLTypeAtom);
  const className = props.className
    ? `${props.className} dotted-bordered-box`
    : "dotted-bordered-box";

  useEffect(() => {
    if (redirectURLType === URLType.WebLink) {
      setCanDo301(true);
    } else {
      setIs301(false);
      setCanDo301(false);
    }
  }, [redirectURLType, setIs301]);

  if (!canDo301) {
    return <br />;
  }

  return (
    <div className={className}>
      <Checkbox
        size="small"
        checked={is301}
        onChange={() => {
          setIs301(!is301);
        }}
        className="align-checkbox"
      />
      Use 301 redirect
      {is301 ? <br /> : null}
      {is301 ? (
        <span style={{ color: "red", padding: "5px" }}>
          <strong>NOTE:</strong>&nbsp;you can not edit social media link preview
          of your links with 301 redirect
        </span>
      ) : null}
    </div>
  );
};

export default Is301Checkbox;
