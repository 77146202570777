import { Stripe } from "@stripe/stripe-js";
import { atom } from "jotai";
import { LOCALSTORAGE_PAYMENT_DONE } from "../data";
import { getInitialCurrency, storeInitialCurrency } from "../helpers";
import { Currency } from "../models";

const storedPaymentDone = localStorage.getItem(LOCALSTORAGE_PAYMENT_DONE);
const paymentDoneToUse = storedPaymentDone ? true : false;
export const paymentDoneAtom = atom<boolean>(paymentDoneToUse);
export const stripeClientAtom = atom<Stripe | null>(null);
export const currencyAtom = atom(
  getInitialCurrency(),
  (_get, set, currency: Currency) => {
    storeInitialCurrency(currency);
    set(currencyAtom, currency);
  }
);