import "./Step3.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SubmitButton from "../../../components/SubmitButton";
import LinkPreview from "../components/LinkPreview";
import LinkPreviewForm from "../components/LinkPreviewForm";
import BackButton from "../components/BackButton";
import { useStep3BL } from "../hooks/BL/step3";
import { LinkFormMode } from "../models";
import { UpgradeDialog } from "../../Billing";
import Is301Checkbox from "../components/Is301Checkbox";

const Step3 = () => {
  const [mode, is301, progressing, showUpgrade, setShowUpgrade, submitStep3] =
    useStep3BL();

  const renderBackButton = () => {
    if (progressing) {
      return null;
    }

    return <BackButton />;
  };

  return (
    <form
      className="link-form-step-3-container has-padding-5"
      onSubmit={async (e) => {
        e.preventDefault();
        submitStep3();
      }}
    >
      <Typography variant="h6">Social Media Link Preview</Typography>
      <br />
      {is301 ? <Is301Checkbox /> : <LinkPreviewForm />}

      <br />
      <LinkPreview />
      <br />
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <Paper className="link-form-step-3-footer">
        <SubmitButton
          className="link-form-step-3-submit"
          showProgress={progressing}
          progressText={
            mode !== LinkFormMode.Update
              ? "Creating the link"
              : "Updating the link"
          }
          defaultText={
            mode !== LinkFormMode.Update ? "Create the link" : "Update the link"
          }
        />
        <div className="link-form-step-3-back">{renderBackButton()}</div>
      </Paper>
      {showUpgrade ? (
        <UpgradeDialog
          showDialog={showUpgrade}
          setShowDialog={setShowUpgrade}
        />
      ) : null}
    </form>
  );
};

export default Step3;
