import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import LinkIcon from "@mui/icons-material/Link";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import { URL_APP_VIEW_ALL_LINKS } from "../../../data/URLs";

type MyLinksListButtonProps = {
  className?: string;
  mobile: boolean;
};

const MyLinksListButton: FC<MyLinksListButtonProps> = ({
  className,
  mobile,
}) => {
  const Navigate = useNavigate();
  const Location = useLocation();
  const highlight = Location.pathname === URL_APP_VIEW_ALL_LINKS;
  const onClick = () => {
    Navigate(URL_APP_VIEW_ALL_LINKS);
  };

  if (mobile) {
    return (
      <MenuItem
        sx={{ p: 2 }}
        selected={highlight}
        className={className}
        onClick={onClick}
      >
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText>My Links</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Button
      className={className}
      variant="text"
      color={highlight ? "primary" : "inherit"}
      onClick={onClick}
    >
      <strong>My Links</strong>
    </Button>
  );
};

export default MyLinksListButton;
