declare global {
  var gtag: any;
}

export const reportSignupToGoogleAds = () => {
  if (process.env.NODE_ENV === "production" && gtag) {
    try {
      gtag('event', 'conversion', {
        'send_to': 'AW-796758396/Xl_KCOnC_ZYYEPyi9vsC'
      });
    } catch(e) {
      console.error(e);
    }
  }
};

export const reportPurchaseToGoogleAds = (transactionID: string = "") => {
  if (process.env.NODE_ENV === "production" && gtag) {
    try {
      gtag('event', 'conversion', {
        'send_to': 'AW-796758396/t9EYCLWJopgYEPyi9vsC',
        'transaction_id': transactionID
      });
    } catch(e) {
      console.error(e);
    }
  }
};
