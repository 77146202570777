import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { removeCharacters } from "../../../utils";
import {
  linkPathAtom,
  validation1Atom,
  subDomainAtom,
  modeAtom,
} from "../states";
import "./URLDesignForm.css";
import { LinkFormMode } from "../models";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FC } from "react";

type URLDesignFormProps = {
  domainNameList: string[]
}

const URLDesignForm: FC<URLDesignFormProps> = ({domainNameList}) => {
  const [mode] = useAtom(modeAtom);

  return (
    <div className="create-link-step-1-subdomain">
      {mode === LinkFormMode.CreateWithCustomDomain ? (
        <DomainSelector domainNameList={domainNameList} />
      ) : (
        <SubDomainInput />
      )}
      <LinkPathInput />
    </div>
  );
};

const DomainSelector:FC<URLDesignFormProps> = ({domainNameList}) => {
  const [subDomain, setSubDomain] = useAtom(subDomainAtom);
  const [, setValidation] = useAtom(validation1Atom);

  return (
    <>
      <Select
        variant="standard"
        inputProps={{ className: "data-hj-allow" }}
        value={subDomain}
        onChange={(e) => {
          setSubDomain(e.target.value);
          setValidation("");
        }}
      >
        {domainNameList.map((domainName) => (
          <MenuItem value={`customDomain-_-@${domainName}`} key={domainName}>
            {domainName}
          </MenuItem>
        ))}
      </Select>
      &nbsp;/&nbsp;
    </>
  );
};

const SubDomainInput = () => {
  const [subDomain, setSubDomain] = useAtom(subDomainAtom);
  const [, setValidation] = useAtom(validation1Atom);

  return (
    <>
      <TextField
        placeholder="Your-Brand"
        autoFocus={true}
        variant="standard"
        inputProps={{
          style: { textAlign: "end" },
          maxLength: 200,
          className: "data-hj-allow",
        }}
        type="text"
        value={subDomain}
        onChange={(e: any) => {
          const subDomain = removeCharacters(e.target.value, [" ", ".", "@", ":", "/"]);
          setSubDomain(subDomain);
          setValidation("");
        }}
      />
      &nbsp;<span className="create-link-step-1-domain">.vstory.co/</span>
      &nbsp;
    </>
  );
};

const LinkPathInput = () => {
  const [linkPath, setLinkPath] = useAtom(linkPathAtom);
  const [, setValidation] = useAtom(validation1Atom);

  return (
    <TextField
      placeholder="Some-meaningful-path"
      variant="standard"
      inputProps={{ maxLength: 200, className: "data-hj-allow" }}
      type="text"
      value={linkPath}
      onChange={(e) => {
        const linkPath = removeCharacters(e.target.value, [" ", "/", "?"]);
        setLinkPath(linkPath);
        setValidation("");
      }}
    />
  );
};

export default URLDesignForm;
