import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { validation2Atom, redirectURLAtom } from "../states";
import { prependProtocolIfNeeded } from "../helpers";

const RedirectDestinationForm = () => {
  const [redirectURL, setRedirectURL] = useAtom(redirectURLAtom);
  const [, setValidation] = useAtom(validation2Atom);

  return (
    <TextField
      type="url"
      fullWidth={true}
      placeholder="https://example.com"
      autoFocus={true}
      variant="standard"
      value={redirectURL}
      className="has-padding-5"
      inputProps={{ className: "data-hj-allow" }}
      style={{ marginBottom: "15px" }}
      onChange={(e) => {
        let urlTyped = e.target.value;
        urlTyped = prependProtocolIfNeeded(urlTyped);
        setRedirectURL(urlTyped);
        setValidation("");
      }}
    />
  );
};

export default RedirectDestinationForm;
