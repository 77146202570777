import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinearProgress from "@mui/material/LinearProgress";
import { FC } from "react";

const WaitingToInstall: FC<{ domainName: string }> = ({ domainName }) => {
  return (
    <>
      <Typography variant="body1" textAlign="center">
        Successfully verified DNS records of {domainName}&nbsp;
        <CheckCircleIcon
          fontSize="inherit"
          color="success"
          sx={{ position: "relative", top: "2px" }}
        />
      </Typography>
      <br />
      <Typography variant="body2" textAlign="center">
        Now setting up {domainName} on our end
      </Typography>
      <div style={{ maxWidth: "650px", width: "100%", padding: "5px 15px" }}>
        <LinearProgress
          sx={{ width: "100%", height: "5px", borderRadius: "20px" }}
          color="success"
        />
      </div>
      <Typography variant="caption" textAlign="center">
        It should only take a few moments
      </Typography>
    </>
  );
};

export default WaitingToInstall;
