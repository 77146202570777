import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { FC, useState } from "react";
import Alert from "@mui/material/Alert";
import { LinkData } from "../models";
import { makeAURL } from "../../../utils";

type CopyButtonProps = {
  data: LinkData;
};

const CopyButton: FC<CopyButtonProps> = ({ data }) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const link = makeAURL(data.subDomain.S, data.linkPath.S);

  return (
    <>
      <Button
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(link);
            setShowSnackBar(true);
          } catch (e) {
            // handle error while copying to clipboard
          }
        }}
      >
        Copy
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        autoHideDuration={2000}
        onClose={() => setShowSnackBar(false)}
      >
        <Alert
          onClose={() => setShowSnackBar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Link {link} has been copied to clipboard
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyButton;
