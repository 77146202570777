import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";

type SubmitButtonProps = {
  showProgress: boolean;
  progressText: string;
  defaultText: string;
  className?: string;
};

const SubmitButton: FC<SubmitButtonProps> = ({
  showProgress,
  progressText,
  defaultText,
  className = ""
}) => {
  return (
    <Button
      type="submit"
      variant="contained"
      className={`is-centered-item ${className}`}
      disabled={showProgress}
      endIcon={
        showProgress ? <CircularProgress size={14} color="inherit" /> : null
      }
    >
      {showProgress ? progressText : defaultText}
    </Button>
  );
};

export default SubmitButton;
