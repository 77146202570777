import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { descriptionOGAtom, titleOGAtom } from "../states";
import "./LinkPreviewForm.css";
import ImageInput from "./ImageInput";

const LinkPreviewForm = () => {
  const [titleOG, setTitleOG] = useAtom(titleOGAtom);
  const [descriptionOG, setDescriptionOG] = useAtom(descriptionOGAtom);

  return (
    <>
      <TextField
        type="text"
        label="Title"
        className="link-form-step-3-form"        
        placeholder="Give some interesting title here"
        autoFocus={true}
        variant="standard"
        value={titleOG}
        inputProps={{ maxLength: 200, className: "data-hj-allow" }}
        onChange={(e) => {
          setTitleOG(e.target.value);
        }}
      />
      <br />
      <TextField
        type="text"
        label="Description"
        className="link-form-step-3-form"
        placeholder="Give some interesting description here"
        variant="standard"
        value={descriptionOG}
        inputProps={{ maxLength: 500, className: "data-hj-allow" }}
        onChange={(e) => {
          setDescriptionOG(e.target.value);
        }}
      />
      <br />
      <ImageInput />
    </>
  );
};

export default LinkPreviewForm;
