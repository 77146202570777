import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import URLDesignForm from "../components/URLDesignForm";
import SubmitButton from "../../../components/SubmitButton";
import { useStep2BL } from "../hooks/BL/step2";
import BackButton from "../components/BackButton";
import { makeAURL } from "../../../utils";
import CustomDomainToggle from "../components/CustomDomainToggle";
import { FC } from "react";

type Step2Props = {
  domainNameList: string[]
}

const Step2: FC<Step2Props> = ({domainNameList}) => {
  const [
    subDomain,
    linkPath,
    progressing,
    validation,
    submitStep2,
  ] = useStep2BL();

  const renderBackButton = () => {
    if (progressing) {
      return null;
    }

    return <BackButton />;    
  };

  return (
    <form
      className="is-centered"
      onSubmit={(e) => {
        e.preventDefault();
        submitStep2();
      }}
    >
      <Typography variant="h6">Design your URL</Typography>
      <Typography variant="body1" textAlign="center">
        This is the link that your audience will first see
      </Typography>
      <Typography
        variant="caption"
        textAlign="center"
        style={{ maxWidth: "500px", padding: "0 10px" }}
      >
        This is your custom vStory link to share and track, it will redirect to
        the URL you provided in step - 1
      </Typography>
      <br />
      <URLDesignForm domainNameList={domainNameList} />
      {subDomain && !validation ? (
        <Typography
          variant="caption"
          textAlign="center"
          color="green"
          fontWeight="800"
          style={{ maxWidth: "500px", padding: "0 10px" }}
        >
          {makeAURL(subDomain, linkPath)}
        </Typography>
      ) : null}
      {validation ? (
        <Alert
          severity="warning"
          variant="outlined"
          className="is-centered-item"
        >
          {validation}
        </Alert>
      ) : null}
      <CustomDomainToggle domainNameList={domainNameList} />            
      <br />
      <SubmitButton
        showProgress={progressing}
        progressText="Checking if available"
        defaultText="Next"
      />
      {renderBackButton()}
    </form>
  );
};

export default Step2;
