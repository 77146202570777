import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { URL_APP_VIEW_ALL_LINKS } from "../../../data/URLs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PaymentSuccess = () => {  
  return (
    <div className="billing-container">
      <CheckCircleIcon sx={{ fontSize: 120 }} color="success" />
      <Typography variant="h5">Congratulations!</Typography>
      <Typography
        variant="body1"
        sx={{ maxWidth: "600px", px: 5 }}
        textAlign="center"
      >
        You're now a pro user of vStory Links!
      </Typography>
      <Typography
        variant="caption"
        sx={{ maxWidth: "400px", px: 5 }}
        textAlign="center"
      >
        Note: It may take a minute or two, to get this reflected into our system.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={{ margin: "25px" }}
        onClick={() => {
          window.location.assign(URL_APP_VIEW_ALL_LINKS);
        }}
      >
        Back To Home
      </Button>
    </div>
  );
};

export default PaymentSuccess;
