import Link from "@mui/material/Link";
import { useAtom } from "jotai";
import { FC } from "react";
import { activeStepAtom } from "../states";
import "./BackButton.css";

type BackButtonProps = {
  text?: string;
  onClick?: Function;
};

const BackButton: FC<BackButtonProps> = (props) => {
  const [, setActiveStep] = useAtom(activeStepAtom);

  return (
    <Link
      color="inherit"
      underline="hover"
      textAlign="center"
      className="margin-top-10-px has-pointer-cursor"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      }}
    >
      { props.text ? props.text : "Back" }
    </Link>
  );
};

export default BackButton;
