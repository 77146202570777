import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { LinkData } from "../models";
import { makeAURL } from "../../../utils";
import { useLinkAnalyticsModule } from "../hooks/BL";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import AnalyticsDialogTagChip from "./AnalyticsDialogTagChip";

const AnalyticsDialog: FC<{
  data: LinkData;
  showDialog: boolean;
  setShowDialog: Function;
}> = ({ data, showDialog, setShowDialog }) => {
  const title = makeAURL(data.subDomain.S, data.linkPath.S)
    .split("https://")
    .join("");
  const [LinkAnalyticsReport, LinkAnalyticsRefreshButton] =
    useLinkAnalyticsModule(showDialog);

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      aria-labelledby="analytics-pop-up-title"
      aria-describedby="analytics-popup-content"
    >      
      <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }}>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "65px",
          }}
        >
          <AnalyticsDialogTagChip tag={data?.tag?.S} />
          {data?.tag?.S ? " " : null}
          {title}
        </div>
        {LinkAnalyticsRefreshButton ? <LinkAnalyticsRefreshButton /> : null}
        <IconButton
          aria-label="close"
          onClick={() => setShowDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        {LinkAnalyticsReport ? (
          <LinkAnalyticsReport fullPaths={[data.fullPath.S]} />
        ) : (
          <Box
            sx={{ p: 2, width: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <br />
            Loading data
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AnalyticsDialog;
