import { ListLinksPaginationParams } from "../models";

export const listLinks = async (
  accessToken: string | null,
  paginationParams?: ListLinksPaginationParams
) => {
  try {
    var url = new URL(
      process.env.REACT_APP_API_GATEWAY_BASEURL + "/links/list"
    );
    if (paginationParams) {
      url.search = new URLSearchParams({
        fullPath: paginationParams.fullPath.S,
        updatedAt: paginationParams.updatedAt.S
      }).toString();
    }
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteLink = async (
  accessToken: string | null,
  fullPath: string
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/link/delete");
  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fullPath }),
  });
  const data = await response.json();
  return data;
};

export const updateLinkTag = async (
  accessToken: string | null,
  fullPath: string,
  tag: string
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/linkTag/update");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fullPath, tag }),
  });
  const data = await response.json();
  return data;
};

export const deleteLinkTag = async (
  accessToken: string | null,
  fullPath: string
) => {
  var url = new URL(process.env.REACT_APP_API_GATEWAY_BASEURL + "/linkTag/delete");
  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fullPath }),
  });
  const data = await response.json();
  return data;
};