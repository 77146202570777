import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import { URL_APP_CREATE_NEW_LINK } from "../../../data/URLs";
import LinkCard from "../components/LinkCard";
import {
  useLoadLinkDataBL,
  useLoadNextSetOfLinkDataBL,
} from "../hooks";
import EmptyScreen from "../components/EmptyScreen";
import { LinkData } from "../models";
import NextLinkSetLoading from "../components/NextLinkSetLoading";
import { useAuthFeature } from "../../Auth";

const LinksList = () => {
  const [linkDataResponse, loading, loaded] = useLoadLinkDataBL();
  const [nextSetLoading] = useLoadNextSetOfLinkDataBL();
  const [, userInfo] = useAuthFeature();
  const Navigate = useNavigate();

  return (
    <>
      <Paper
        style={{
          position: "fixed",
          top: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          zIndex: 100,
        }}
        variant="outlined"
      >
        <Container maxWidth="xl">
          <Typography variant="h6" sx={{ my: 2 }} align="center">
            My Links&nbsp;&nbsp;(
            <Button
              onClick={() => {
                if (userInfo?.disabled) {
                  alert(userInfo?.disabled);
                } else {
                  Navigate(URL_APP_CREATE_NEW_LINK);
                }
              }}
              variant="text"
            >
              Create New Link
            </Button>
            )
          </Typography>
        </Container>
        {loading ? <LinearProgress sx={{ width: "100%" }} /> : null}
      </Paper>
      <Container maxWidth="xl" sx={{ marginTop: "150px" }}>
        {linkDataResponse?.list?.length > 0 ? (
          <Grid container spacing={2} justifyContent="center">
            {linkDataResponse.list.map((data: LinkData) => {
              return (
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={"link-card-" + data.fullPath.S}
                >
                  <LinkCard data={data} />
                </Grid>
              );
            })}
          </Grid>
        ) : !loading && loaded ? (
          <EmptyScreen />
        ) : null}
        {nextSetLoading ? <NextLinkSetLoading /> : null}
      </Container>
    </>
  );
};

export default LinksList;
