import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  domainNameAtom,
  resetDomainFormActionAtom,
} from "../states";
import { useAtom } from "jotai";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { URL_APP_VIEW_ALL_DOMAINS } from "../../../data/URLs";
import BackButton from "./BackButton";
import { useLinkFormForCustomDomain } from "../../LinkForm";

const SuccessStep = () => {
  const [domainName] = useAtom(domainNameAtom);
  const [, resetDomainFormAction] = useAtom(resetDomainFormActionAtom);
  const proceedToCreateLink = useLinkFormForCustomDomain();
  const Navigate = useNavigate();

    return (
      <>
        <CheckCircleIcon sx={{ fontSize: 120 }} color="success" />
        <Typography variant="h2">It's Ready!</Typography>
        <Typography variant="body1" align="center" sx={{ padding: "0 15px" }}>
          You can now create links with{" "}
          <strong style={{ display: "inline-block" }}>{domainName}</strong>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ margin: "25px" }}
          onClick={() => {
            proceedToCreateLink(domainName);
          }}
        >
          Create Link With this domain
        </Button>
        <BackButton
          text="Back to Home"
          onClick={() => {
            resetDomainFormAction(null);
            Navigate(URL_APP_VIEW_ALL_DOMAINS);
          }}
        />
      </>
    );
};

export default SuccessStep;
