import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import "./EmptyScreen.css";
import { URL_APP_CREATE_NEW_LINK } from "../../../data/URLs";
import { useNavigate } from "react-router-dom";

const EmptyScreen = () => {
  const Navigate = useNavigate();

  return (
    <div className="link-list-empty-screen">
      <AddReactionIcon sx={{ fontSize: 120 }} color="success" />
      <Typography variant="h5">Get Started</Typography>
      <Typography variant="body1">by creating your very first vStory Link</Typography>
      <Button variant="contained" color="primary" sx={{m: 2}} onClick={() => Navigate(URL_APP_CREATE_NEW_LINK)}>Create New Link</Button>
    </div>
  );
}

export default EmptyScreen;