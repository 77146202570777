declare global {
  var hj: any;
}

export const identifyUserForHotjar = (
  userID: string,
  name: string,
  email: string
) => {
  if (process.env.NODE_ENV === "production" && hj) {
    try {
      hj('identify', userID, {
        email,
        name
      });
    } catch(e) {
      console.error(e);
    }
  }
};