import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { extractSubDomain } from "../helpers";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAtom } from "jotai";
import { domainNameAtom, ipAtom } from "../states";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";

const DNSRecordsTable = () => {
  const [ip] = useAtom(ipAtom);
  const [domainName] = useAtom(domainNameAtom);
  const [showSnackBar, setShowSnackBar] = useState<string>("");

  return (
    <>
      <Paper
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px"
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Host</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>A</TableCell>
                <TableCell>
                  {extractSubDomain(domainName)}&nbsp;
                  <IconButton
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(
                          extractSubDomain(domainName)
                        );
                        setShowSnackBar("Host is copied to the clipboard");
                      } catch (e) {
                        // handle error while copying to clipboard
                      }
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {ip}&nbsp;
                  <IconButton
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(ip);
                        setShowSnackBar("Value is copied to the clipboard");
                      } catch (e) {
                        // handle error while copying to clipboard
                      }
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={!!showSnackBar}
        autoHideDuration={2000}
        onClose={() => setShowSnackBar("")}
      >
        <Alert
          onClose={() => setShowSnackBar("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {showSnackBar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DNSRecordsTable;
