import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { makeFullPath } from "../../../utils";
import { useAuthFeature } from "../../Auth";
import { listLinks } from "../helpers";
import {
  LinkData,
  LinkDataResponse,
  ListLinksPaginationParams,
} from "../models";
import {
  addToLinksDataListAtom,
  deleteFromLinksDataListAtom,
  updateLinksDataListAtom,
  updateTagInLinksDataListAtom,
  deleteTagFromLinksDataListAtom,
  linkDataLoadingAtom,
  linkDataLoadedAtom,
  linkDataResponseAtom,
  linkDataNextPageLoadingAtom,
} from "../states";

export const useLinkData = (): [
  addToLinksDataList: (
    subDomain: string,
    linkPath: string,
    redirectURL: string,
    is301: boolean,
    titleOG: string,
    descriptionOG: string,
    imageURLOG: string,
    imageTypeOG: string
  ) => void,
  updateLinksDataList: (
    fullPath: string,
    redirectURL: string,
    is301: boolean,
    titleOG: string,
    descriptionOG: string,
    imageURLOG: string,
    imageTypeOG: string
  ) => void,
  deleteFromLinksDataList: (fullPath: string) => void,
  updateTagInLinksDataList: (fullPath: string, tag: string) => void,
  deleteTagFromLinksDataList: (fullPath: string) => void
] => {
  const [, addToLinksDataListAction] = useAtom(addToLinksDataListAtom);
  const [, updateLinksDataListAction] = useAtom(updateLinksDataListAtom);
  const [, deleteFromLinksDataList] = useAtom(deleteFromLinksDataListAtom);
  const [, updateTagInLinksDataListAction] = useAtom(
    updateTagInLinksDataListAtom
  );
  const [, deleteTagFromLinksDataList] = useAtom(
    deleteTagFromLinksDataListAtom
  );

  const addToLinksDataList = (
    subDomain: string,
    linkPath: string,
    redirectURL: string,
    is301: boolean,
    titleOG: string,
    descriptionOG: string,
    imageURLOG: string,
    imageTypeOG: string
  ) => {
    const linkData: LinkData = {
      fullPath: {
        S: makeFullPath(subDomain, linkPath),
      },
      subDomain: {
        S: subDomain,
      },
      linkPath: {
        S: linkPath,
      },
      redirectURL: {
        S: redirectURL,
      },
      is301: {
        BOOL: is301,
      },
      titleOG: {
        S: titleOG,
      },
      descriptionOG: {
        S: descriptionOG,
      },
      imageURLOG: {
        S: imageURLOG,
      },
      imageTypeOG: {
        S: imageTypeOG,
      },
      viewsCount: {
        N: "0",
      },
      uniqueVisitorsCount: {
        N: "0",
      },
      createdAt: {
        S: new Date().toISOString(),
      },
      updatedAt: {
        S: new Date().toISOString(),
      },
    };
    addToLinksDataListAction(linkData);
  };

  const updateLinksDataList = (
    fullPath: string,
    redirectURL: string,
    is301: boolean,
    titleOG: string,
    descriptionOG: string,
    imageURLOG: string,
    imageTypeOG: string
  ) => {
    updateLinksDataListAction([
      fullPath,
      redirectURL,
      is301,
      titleOG,
      descriptionOG,
      imageURLOG,
      imageTypeOG,
    ]);
  };

  const updateTagInLinksDataList = (fullPath: string, tag: string) => {
    updateTagInLinksDataListAction([fullPath, tag]);
  };

  return [
    addToLinksDataList,
    updateLinksDataList,
    deleteFromLinksDataList,
    updateTagInLinksDataList,
    deleteTagFromLinksDataList,
  ];
};

export const useLoadLinkDataFn = (): [loadLinkData: Function] => {
  const [,,getAuthToken] = useAuthFeature();
  const [, setLoading] = useAtom(linkDataLoadingAtom);
  const [, setLoaded] = useAtom(linkDataLoadedAtom);
  const [, setLinkDataResponse] = useAtom(linkDataResponseAtom);

  const loadLinkData = useCallback(async () => {
    setLoaded(false);
    setLoading(true);
    const accessToken = await getAuthToken();
    const data = await listLinks(accessToken);
    setLinkDataResponse({
      list: data.Items,
      paginationParams: data.LastEvaluatedKey,
    });
    setLoaded(true);
    setLoading(false);
  }, [getAuthToken, setLoading, setLoaded, setLinkDataResponse]);

  return [loadLinkData];
};

export const useLoadLinkDataBL = (): [
  linkDataResponse: LinkDataResponse,
  loading: boolean,
  loaded: boolean
] => {
  const [isAuthenticated] = useAuthFeature();
  const [loading] = useAtom(linkDataLoadingAtom);
  const [loaded, setLoaded] = useAtom(linkDataLoadedAtom);
  const [linkDataResponse] = useAtom(linkDataResponseAtom);
  const [loadLinkData] = useLoadLinkDataFn();

  useEffect(() => {
    setLoaded(false);
  }, [setLoaded]);

  useEffect(() => {
    (async () => {
      if (!loading && !loaded && isAuthenticated) {
        loadLinkData();
      }
    })();
  }, [isAuthenticated, loading, loaded, loadLinkData]);

  return [linkDataResponse, loading, loaded];
};

export const useLoadNextSetOfLinkDataBL = (): [nextPageLoading: boolean] => {
  const [,,getAuthToken] = useAuthFeature();
  const [nextPageLoading, setNextPageLoading] = useAtom(
    linkDataNextPageLoadingAtom
  );
  const [linkDataResponse, setLinkDataResponse] = useAtom(linkDataResponseAtom);

  const loadNextSetOfLinkData = useCallback(
    async (paginationParams?: ListLinksPaginationParams) => {
      setNextPageLoading(true);
      setLinkDataResponse({
        list: linkDataResponse.list,
        paginationParams: null,
      });
      const accessToken = await getAuthToken();
      const data = await listLinks(accessToken, paginationParams);
      setLinkDataResponse({
        list: [...linkDataResponse.list, ...data.Items],
        paginationParams: data.LastEvaluatedKey,
      });
      setNextPageLoading(false);
    },
    [getAuthToken, linkDataResponse, setNextPageLoading, setLinkDataResponse]
  );

  useEffect(() => {
    const loadMoreLinksIfNeeded = () => {
      const scrolledToBottom =
        window.scrollY + window.innerHeight > document.body.scrollHeight;
      const moreLinksExistInDB = linkDataResponse.paginationParams;
      if (scrolledToBottom && moreLinksExistInDB) {
        loadNextSetOfLinkData(moreLinksExistInDB);
      }
    };
    document.addEventListener("scroll", loadMoreLinksIfNeeded);
    return () => {
      document.removeEventListener("scroll", loadMoreLinksIfNeeded);
    };
  }, [linkDataResponse, loadNextSetOfLinkData]);

  return [nextPageLoading];
};
