import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { atom } from "jotai";
import { URL_APP_HOME } from "../../../data/URLs";
import { resetLinkFormActionAtom } from "../../LinkForm";
import { UserInfo } from "../models";

// UI
export const signUpNameAtom = atom<string>("");
export const signInEmailAtom = atom<string>("");
export const OTPAtom = atom<string>("");
export const loadingAtom = atom<boolean>(true);
export const progressingAtom = atom<boolean>(false);

// Non-UI
export const authUserAtom = atom<CognitoUser | null>(null);
export const authUserInfoAtom = atom<UserInfo | null>(null);
export const authSessionAtom = atom<CognitoUserSession | null>(null);
export const redirectURLAtom = atom<string>(URL_APP_HOME);

export const authResetActionAtom = atom(null,
  (_get, set) => {
    set(authSessionAtom, null);
    set(authUserAtom, null);
    set(authUserInfoAtom, null);
    set(OTPAtom, "");
    set(resetLinkFormActionAtom, null);
  }
);
