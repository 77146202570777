import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { URL_APP_BILLING } from "../../../data/URLs";
import { usePaymentNeededBL } from "../hooks/BL";
import CircleIcon from "@mui/icons-material/Circle";
import { FC } from "react";

type BillingButtonProps = {
  className: string;
  hightlight: boolean;
};

const BillingButton: FC<BillingButtonProps> = ({ className, hightlight }) => {
  const Location = useLocation();
  const [paymentNeeded, userInfo] = usePaymentNeededBL();
  const Navigate = useNavigate();
  const showUpgradeButton = userInfo && paymentNeeded;
  const shouldHighligh =
    hightlight && Location.pathname !== URL_APP_BILLING && showUpgradeButton;

  return (
    <Button
      className={className}
      disabled={!userInfo}
      variant="text"
      color={Location.pathname === URL_APP_BILLING ? "primary" : "inherit"}
      onClick={() => Navigate(URL_APP_BILLING)}
    >
      <strong>{showUpgradeButton ? "Upgrade" : "Billing"}</strong>
      {shouldHighligh ? <MarkHighlighted /> : null}
    </Button>
  );
};

const MarkHighlighted = () => (
  <CircleIcon
    sx={{ fontSize: 8, position: "relative", top: "-5px" }}
    color="error"
  />
);

export default BillingButton;
