import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useAtom } from "jotai";
import { activeStepAtom } from "../states";

const stepsForCreation = [  
  "Domain You Own",
  "Add DNS Record",
  "Verify & Integrate",
];

const StepperStrip = () => {
  const [activeStep] = useAtom(activeStepAtom);
  let steps = stepsForCreation;

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{ zIndex: 1200, marginTop: "67px" }}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean; className?: string } = {
          className: "has-pointer-cursor",
        };
        return (
          <Step key={label + index} {...stepProps}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperStrip;
