import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import "./AuthLoading.css";

const AuthLoadng = () => {
  return (
    <div className="auth-loading">
      <CircularProgress />
      <br />
      <Typography variant="h2" component="h2">
        Loading
      </Typography>      
    </div>
  );
};

export default AuthLoadng;
