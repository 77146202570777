import { useAtom } from "jotai";
import { prepareDomainFormActionAtom } from "../states";
import { DomainData } from "../../DomainsList";
import { URL_APP_ADD_DOMAIN } from "../../../data/URLs";
import { useNavigate } from "react-router-dom";

export const useDomainFormToContinue = () => {
  const [, prepareDomainFormAction] = useAtom(prepareDomainFormActionAtom);
  const Navigate = useNavigate();
  return (domainData: DomainData) => {
    prepareDomainFormAction(domainData);
    Navigate(URL_APP_ADD_DOMAIN);
  };
};
