import { useAtom } from "jotai";
import { FC, PropsWithChildren } from "react";
import {
  loadingAtom,
} from "../states";
import AuthLoadng from "../components/AuthLoading";
import { useSessionBL } from "../hooks/BL/session";

const Auth: FC<PropsWithChildren> = (props) => {
  useSessionBL();
  const [loading] = useAtom(loadingAtom);

  if (loading) {
    return <AuthLoadng />;
  }

  return <>{props.children}</>;
};

export default Auth;
